import React, { useEffect, useState } from "react";
import * as yup from "yup";
import Modal from "../../components/Modal";
import Loading from "../../components/Loading";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import NewCityModal from "./NewCityModal";
import {
  useAddCity,
  useDeleteCity,
  useGetCountries,
} from "../../services/addresses.services";
import authApi from "../../config/authApi.config"; // Подключаем authApi для запросов

const Cities: React.FC = () => {
  const [isNewCityModalOpen, setIsNewCityModalOpen] = useState<boolean>(false);
  const [citiesData, setCitiesData] = useState<any[]>([]); // Храним все города
  const [loadingCities, setLoadingCities] = useState<boolean>(false);

  const NewCityFormSchema = yup.object().shape({
    countryId: yup.number().required("Выберите страну!"),
    city: yup.string().required("Название города является обязательным полем!"),
  });

  const useNewCityReturnForm = useForm({
    resolver: yupResolver(NewCityFormSchema),
  });

  const { reset } = useNewCityReturnForm;

  // Получаем страны
  const { countries, isLoading: isLoadingCountries } = useGetCountries();

  // Функция для получения городов с параметром countryId
  const fetchCitiesByCountry = async (countryId: number) => {
    return authApi
      .get(`/addresses/cities`, { params: { countryId } })
      .then((res) => res.data)
      .catch((error) => {
        throw new Error(error);
      });
  };

  // Запрашиваем города для всех стран после того, как страны будут загружены
  useEffect(() => {
    if (countries && countries.length > 0) {
      setLoadingCities(true); // Начало загрузки городов

      const fetchCitiesForAllCountries = async () => {
        try {
          // Выполняем запросы городов для каждой страны
          const citiesRequests = countries.map((country: any) =>
            fetchCitiesByCountry(country.id)
          );
          const citiesResults = await Promise.all(citiesRequests); // Ждем выполнения всех запросов

          // Объединяем все города в один массив
          const allCities = citiesResults.flat();

          setCitiesData(allCities); // Сохраняем все города в единый массив
        } catch (error) {
          console.error("Ошибка загрузки городов: ", error);
        } finally {
          setLoadingCities(false); // Завершаем загрузку
        }
      };

      fetchCitiesForAllCountries();
    }
  }, [countries]);

  const { mutate: addCity } = useAddCity(
    () => {
      setIsNewCityModalOpen(false);
      toast.success("Город успешно добавлен!");
      reset();
      // Перезагружаем города после добавления
      if (countries) {
        setCitiesData([]); // Очищаем города для перезагрузки
        const fetchCitiesForAllCountries = async () => {
          const citiesRequests = countries.map((country: any) =>
            fetchCitiesByCountry(country.id)
          );
          const citiesResults = await Promise.all(citiesRequests);
          const allCities = citiesResults.flat();
          setCitiesData(allCities);
        };

        fetchCitiesForAllCountries();
      }
    },
    () => {
      toast.error("Что-то пошло не так!");
    }
  );

  const { mutate: deleteCity } = useDeleteCity(
    () => {
      toast.success("Город успешно удалён!");
      // Перезагружаем города после удаления
      if (countries) {
        setCitiesData([]); // Очищаем города для перезагрузки
        const fetchCitiesForAllCountries = async () => {
          const citiesRequests = countries.map((country: any) =>
            fetchCitiesByCountry(country.id)
          );
          const citiesResults = await Promise.all(citiesRequests);
          const allCities = citiesResults.flat();
          setCitiesData(allCities);
        };

        fetchCitiesForAllCountries();
      }
    },
    () => {
      toast.error("Что-то пошло не так!");
    }
  );

  const handleSubmitNewCityForm = (data: {
    countryId: number;
    city: string;
  }) => {
    addCity(data);
  };

  const handleDeleteCity = (id: number) => {
    deleteCity(id);
  };

  if (isLoadingCountries || loadingCities) return <Loading />;

  return (
    <main className="main exchange">
      <div className="exchange__header">
        <h1 className="exchange__title title">Доступные города</h1>
        <button
          onClick={() => setIsNewCityModalOpen(true)}
          className="exchange__button button button--top"
        >
          Добавить новый город
        </button>
      </div>

      {/* Отображаем все города в одном списке */}
      <div className="exchange__table table-exchange table">
        <div className="table-exchange__items table__items table__items--head">
          <div className="table__item">ID</div>
          <div className="table__item">Страна</div>
          <div className="table__item">Название города</div>
          <div className="table__item"></div>
          <div className="table__item"></div>
        </div>
        {citiesData?.map(
          (city: { id: number; city: string; country: number }) => (
            <div className="table-exchange__items table__items" key={city.id}>
              <div className="table__item">{city.id}</div>
              <div className="table__item">
                {
                  countries?.filter((item: any) => {
                    return item.id === +city.country;
                  })[0]?.country
                }
              </div>
              <div className="table__item">{city.city}</div>
              <div className="table__item"></div>
              <div className="table__item">
                <button
                  onClick={() => handleDeleteCity(city.id)}
                  className="table__item-img"
                >
                  <img
                    className="table__item-icon"
                    src="/img/svg/del.svg"
                    alt="icon"
                  />
                </button>
              </div>
            </div>
          )
        )}
      </div>

      <Modal
        title="Новый город"
        isOpen={isNewCityModalOpen}
        onClose={() => setIsNewCityModalOpen(false)}
      >
        <NewCityModal
          returnFormInstance={useNewCityReturnForm}
          onSubmit={handleSubmitNewCityForm}
        />
      </Modal>
    </main>
  );
};

export default Cities;
