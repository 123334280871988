import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";

interface IProps {
  returnFormInstance: UseFormReturn<{
    fee: number;
    fromDate?: string;
  }>;
  onSubmit: (data: { fee: number; fromDate?: string }) => void;
  user: any;
}

const UpdateUserFeeModal: React.FC<IProps> = ({
  returnFormInstance,
  onSubmit,
  user,
}) => {
  const [isDefered, setIsDefered] = useState<boolean>(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = returnFormInstance;

  return (
    <div className="fees">
      <div className="fees__current">Текущее значение {user?.fee}%</div>
      <form
        onSubmit={handleSubmit((data) => onSubmit(data))}
        className="fees__form form"
      >
        <div className="form__items">
          <div className="form__item">
            <label htmlFor="fee" className="form__label">
              Новое значение
            </label>
            <input
              className="form-control"
              type="text"
              defaultValue={user?.fee}
              {...register("fee")}
            />
            {errors.fee && <p className="error">{errors.fee.message}</p>}
          </div>
          {isDefered && (
            <div className="form__item">
              <label htmlFor="fromDate" className="form__label">
                Дата и время начала действия
              </label>
              <input
                className="form-control"
                type="datetime-local"
                {...register("fromDate")}
              />
              {errors.fromDate && (
                <p className="error">{errors.fromDate.message}</p>
              )}
            </div>
          )}
        </div>
        <div className="fees__checkbox checkbox">
          <input
            id="fees3"
            type="checkbox"
            name="open"
            className="checkbox__input"
            checked={isDefered}
            onChange={() => setIsDefered((prev) => !prev)}
          />
          <label htmlFor="fees3" className="checkbox__label">
            Отложеный запуск
          </label>
        </div>
        <button type="submit" className="button">
          Изменить
        </button>
      </form>
    </div>
  );
};

export default UpdateUserFeeModal;
