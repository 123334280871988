import React, { useState } from "react";
import * as yup from "yup";
import {
  useAddCurrencyPair,
  useDeleteCurrencyPair,
  useGetCurrencyPairs,
  useUpdateCurrencyPair,
} from "../../services/currency-pairs.services";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IAddCurrencyPairRequestData } from "../../models/currency-pairs.models";
import Modal from "../../components/Modal";
import NewCurrencyPairModal from "./NewCurrencyPairModal";
import Loading from "../../components/Loading";
import UpdateCurrencyPairModal from "./UpdateCurrencyPairModal";

const Exchange: React.FC = () => {
  const [isNewCurrencyPairModalOpen, setIsNewCurrencyPairModalOpen] =
    useState<boolean>(false);
  const [isUpdateCurrencyPairModalOpen, setIsUpdateCurrencyPairModalOpen] =
    useState<boolean>(false);

  const [selectedCurrencyPairId, setSelectedCurrencyPairId] = useState<
    number | null
  >(null); // Храним ID выбранной валюты

  const { currencyPairs, refetch, isLoading } = useGetCurrencyPairs();

  const { mutate: deleteCurrencyPair } = useDeleteCurrencyPair(
    () => {
      refetch();
      toast.success("Пара валют успешно удалена!");
    },
    () => {
      toast.error("Что-то пошло не так...");
    }
  );

  const { mutate: addCurrencyPair } = useAddCurrencyPair(
    () => {
      toast.success("Пара успешно создана!");
      setIsNewCurrencyPairModalOpen(false);
      reset();
      refetch();
    },
    () => {
      toast.error("Что-то пошло не так...");
    }
  );

  const { mutate: updateCurrencyPair } = useUpdateCurrencyPair(() => {
    setIsUpdateCurrencyPairModalOpen(false);
    refetch();
    toast.success("Пара успешно обновлена!");
  });

  const UpdateCurrencyPairSchema = yup.object().shape({
    exchangeRate: yup.number().required("Укажите новых курс выбранной пары!"),
  });

  const NewCurrencyPairSchema = yup.object().shape({
    baseCurrencyId: yup.number().required("Выберите первую валюту!"),
    targetCurrencyId: yup.number().required("Выберите вторую валюту!"),
    exchangeRate: yup.number().required("Укажите курс выбранных валют!"),
  });

  const useNewCurrencyPairReturnForm = useForm({
    resolver: yupResolver(NewCurrencyPairSchema),
  });

  const useUpdateCurrencyPairReturnForm = useForm({
    resolver: yupResolver(UpdateCurrencyPairSchema),
  });

  const { reset } = useNewCurrencyPairReturnForm;

  const handleSubmitNewCurrencyPairForm = (
    data: IAddCurrencyPairRequestData
  ) => {
    addCurrencyPair(data);
  };

  const handleSubmitUpdateCurrencyPairForm = (data: {
    exchangeRate: number;
  }) => {
    updateCurrencyPair({
      ...data,
      id: selectedCurrencyPairId!,
    });
  };

  const handleUpdateCurrencyPair = (id: number) => {
    setIsUpdateCurrencyPairModalOpen(true);
    setSelectedCurrencyPairId(id);
  };

  return (
    <main className="main exchange">
      {isLoading && <Loading />}
      <div className="exchange__header">
        <h1 className="exchange__title title">Управление курсами</h1>
        <button
          onClick={() => setIsNewCurrencyPairModalOpen(true)}
          className="exchange__button button button--top"
        >
          Добавить новую пару
        </button>
      </div>

      <div className="exchange__table table-exchange table">
        <div className="table-exchange__items table__items table__items--head">
          <div className="table__item">Из</div>
          <div className="table__item"></div>
          <div className="table__item">В</div>
          <div className="table__item">Курс обмена</div>
          <div className="table__item"></div>
        </div>
        {currencyPairs?.map((currencyPair) => (
          <div className="table-exchange__items table__items">
            <div className="table__item">
              1 {currencyPair?.baseCurrencyId?.code}
            </div>
            <div className="table__item">
              <img
                className="table__item-icon"
                src="/img/svg/swap.svg"
                alt="swap-icon"
              />
            </div>
            <div className="table__item">
              {currencyPair?.targetCurrencyId?.code}
            </div>
            <div className="table__item">{currencyPair?.exchangeRate}</div>
            <div className="table__item">
              <div className="table__item-action">
                <button
                  onClick={() => handleUpdateCurrencyPair(currencyPair?.id)}
                  className="table__item-img"
                >
                  <img
                    className="table__item-icon"
                    src="/img/svg/edit.svg"
                    alt="icon"
                  />
                </button>
                <button
                  onClick={() =>
                    deleteCurrencyPair(currencyPair?.id?.toString())
                  }
                  className="table__item-img"
                >
                  <img
                    className="table__item-icon"
                    src="/img/svg/del.svg"
                    alt="icon"
                  />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal
        title="Новая пара валют"
        isOpen={isNewCurrencyPairModalOpen}
        onClose={() => setIsNewCurrencyPairModalOpen(false)}
      >
        <NewCurrencyPairModal
          returnFormInstance={useNewCurrencyPairReturnForm}
          onSubmit={handleSubmitNewCurrencyPairForm}
        />
      </Modal>
      <Modal
        title="Изменение пары"
        isOpen={isUpdateCurrencyPairModalOpen}
        onClose={() => setIsUpdateCurrencyPairModalOpen(false)}
      >
        <UpdateCurrencyPairModal
          returnFormInstance={useUpdateCurrencyPairReturnForm}
          onSubmit={handleSubmitUpdateCurrencyPairForm}
        />
      </Modal>
    </main>
  );
};

export default Exchange;
