import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { IAddCurrencyPairRequestData } from "../../models/currency-pairs.models";
import { useGetCurrencyList } from "../../services/currencies.services";

interface IProps {
  returnFormInstance: UseFormReturn<IAddCurrencyPairRequestData>;
  onSubmit: (data: IAddCurrencyPairRequestData) => void;
}

const NewCurrencyPairModal: React.FC<IProps> = ({
  returnFormInstance,
  onSubmit,
}) => {
  const { currencyList } = useGetCurrencyList();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = returnFormInstance;

  return (
    <div className="create-admin">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="create-admin__form form"
      >
        {/* Первая валюта */}
        <Controller
          name="baseCurrencyId"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="baseCurrencyId" className="form__label">
                Первая Валюта
              </label>
              <select
                id="baseCurrencyId"
                className="form-control"
                value={value}
                onChange={onChange}
              >
                <option value="">Выберите валюту</option>
                {currencyList?.map((currency) => (
                  <option key={currency.id} value={currency.id}>
                    {currency.code} - {currency.name}
                  </option>
                ))}
              </select>
              {errors.baseCurrencyId?.message && (
                <p className="error">{errors.baseCurrencyId?.message}</p>
              )}
            </div>
          )}
        />

        {/* Вторая валюта */}
        <Controller
          name="targetCurrencyId"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="targetCurrencyId" className="form__label">
                Вторая Валюта
              </label>
              <select
                id="targetCurrencyId"
                className="form-control"
                value={value}
                onChange={onChange}
              >
                <option value="">Выберите валюту</option>
                {currencyList?.map((currency) => (
                  <option key={currency.id} value={currency.id}>
                    {currency.code} - {currency.name}
                  </option>
                ))}
              </select>
              {errors.targetCurrencyId?.message && (
                <p className="error">{errors.targetCurrencyId?.message}</p>
              )}
            </div>
          )}
        />

        {/* Обменный курс */}
        <Controller
          name="exchangeRate"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="exchangeRate" className="form__label">
                Обменный курс
              </label>
              <input
                id="exchangeRate"
                className="form-control"
                type="number"
                step="0.01" // Позволяет вводить числа с плавающей запятой
                placeholder="Введите курс обмена"
                value={value}
                onChange={onChange}
              />
              {errors.exchangeRate?.message && (
                <p className="error">{errors.exchangeRate?.message}</p>
              )}
            </div>
          )}
        />

        {/* Кнопка добавления */}
        <button
          type="submit"
          className="create-admin__button button"
          data-animation="fadeInUp"
        >
          Обновить
        </button>
      </form>
    </div>
  );
};

export default NewCurrencyPairModal;
