import { useQuery } from "@tanstack/react-query";
import authApi from "../config/authApi.config";

// const buildQueryParams = (params: any) => {
//   return Object.keys(params)
//     .filter((key) => params[key] !== undefined && params[key] !== null)
//     .map(
//       (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
//     )
//     .join("&");
// };

const getOrderHistory = async ({
  page = 1,
  limit = 10,
  keyword = "",
  statuses = [],
  orderTypes = [],
  isCash = null,
  sort = "",
}: {
  page: number;
  limit: number;
  keyword?: string;
  statuses?: number[];
  orderTypes?: number[];
  isCash?: boolean | null;
  sort?: string;
}) => {
  const params = {
    page,
    limit,
    keyword,
    statuses,
    orderTypes,
    isCash,
    sort,
  };

  // Filter out any params that are undefined or null
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([_, v]) => v !== undefined && v !== null)
  );

  return authApi
    .get("/orders", { params: filteredParams })
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useGetOrderHistory = ({
  page = 1,
  limit = 10,
  keyword = "",
  statuses = [],
  orderTypes = [],
  isCash = null,
  sort = "",
}: {
  page: number;
  limit: number;
  keyword?: string;
  statuses?: number[];
  orderTypes?: number[];
  isCash?: boolean | null;
  sort?: string;
}) => {
  const {
    data: orders,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [
      "OrderHistory",
      page,
      limit,
      keyword,
      statuses,
      orderTypes,
      isCash,
      sort,
    ],
    queryFn: () =>
      getOrderHistory({
        page,
        limit,
        keyword,
        statuses,
        orderTypes,
        isCash,
        sort,
      }),
  });

  return { orders, isLoading, isError };
};
