import React, { useState, useEffect } from "react";
import { useGetOrderHistory } from "../../services/orders.services";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";
import OrderChatModal from "./OrderChatModal";

// Константы для фильтров и статусов
const FILTER_OPTIONS = [
  { id: "dispute", label: "Споры", type: "statuses", value: [11] },
  {
    id: "in_process",
    label: "В процессе",
    type: "statuses",
    value: [1, 2, 3, 4, 5],
  },
  { id: "completed", label: "Исполнен", type: "statuses", value: [9] },
  { id: "trader2", label: "Trader 2", type: "statuses", value: [7] },
  { id: "direct", label: "Прямые", type: "orderTypes", value: [1] },
  { id: "cash", label: "Наличные", type: "isCash", value: true },
];

const getOrderStatusLabel = (status: number) => {
  switch (status) {
    case 1:
      return "Ожидает трейдера";
    case 2:
      return "Трейдер найден";
    case 3:
      return "Клиент оплатил";
    case 4:
      return "Трейдер получил";
    case 5:
      return "Трейдер оплатил";
    case 6:
      return "Сделка завершена";
    case 8:
      return "Сделка отменена";
    case 9:
      return "Сделка закрыта";
    default:
      return "Неизвестный статус";
  }
};

const History: React.FC = () => {
  const [order, setOrder] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [keyword] = useState("");
  const [selectedFilters, setSelectedFilters] = useState<Record<string, any>>(
    {}
  );
  const [sort] = useState("");
  const [isChatModalOpen, setIsChatModalOpen] = useState(false); // Состояние для управления модальным окном
  const [ticketId, setTicketId] = useState<number | null>(null); // Состояние для хранения ticketId

  // Преобразуем выбранные фильтры в параметры для запроса
  const filterParams = Object.keys(selectedFilters).reduce((acc, key) => {
    const filter = FILTER_OPTIONS.find((option) => option.id === key);
    if (filter) {
      acc[filter.type] = filter.value;
    }
    return acc;
  }, {} as Record<string, any>);

  const { orders, isLoading, isError, hasMore } = useGetOrderHistory({
    page,
    limit,
    keyword,
    ...filterParams,
    sort,
  });

  // Обработчик скролла для пагинации
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop <
      document.documentElement.offsetHeight - 100
    )
      return;
    if (hasMore && !isLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  // Обработчик изменения фильтров
  const handleFilterChange = (filterId: string) => {
    setSelectedFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      if (newFilters[filterId]) {
        delete newFilters[filterId];
      } else {
        newFilters[filterId] = true;
      }
      return newFilters;
    });
    setPage(1); // Сбрасываем страницу при изменении фильтров
  };

  // Обработчик открытия модального окна чата
  const handleOpenChatModal = (ticketId: number, order: any) => {
    setTicketId(ticketId); // Сохраняем ticketId
    setIsChatModalOpen(true); // Открываем модальное окно
    setOrder(order);
  };

  // Обработчик закрытия модального окна чата
  const handleCloseChatModal = () => {
    setIsChatModalOpen(false); // Закрываем модальное окно
    setTicketId(null); // Сбрасываем ticketId
  };

  return (
    <main className="main history">
      {isLoading && <Loading />}
      <h1 className="history__title title">История Транзакций</h1>

      {/* Фильтры */}
      <div className="history__filter filter">
        {FILTER_OPTIONS.map((option) => (
          <div key={option.id} className="filter__item">
            <input
              id={option.id}
              type="checkbox"
              className="filter__input"
              checked={!!selectedFilters[option.id]}
              onChange={() => handleFilterChange(option.id)}
            />
            <label htmlFor={option.id} className="filter__label">
              {option.label}
            </label>
          </div>
        ))}
      </div>

      {/* Таблица с заказами */}
      <div className="history__table table-history table">
        <div className="table-history__items table__items table__items--head">
          <div className="table__item">Customer ID</div>
          <div className="table__item">Trader 1 ID</div>
          <div className="table__item">Trader 2 ID</div>
          <div className="table__item">Order ID</div>
          <div className="table__item">From</div>
          <div className="table__item">To</div>
          <div className="table__item">Status</div>
          <div className="table__item">Action</div>
        </div>
        {orders?.map((order: any) => (
          <div key={order.id} className="table-history__items table__items">
            <div className="table__item table__item--id">
              {order.customer?.id}
            </div>
            <div className="table__item">{order.trader1?.id}</div>
            <div className="table__item">{order.trader2?.id || "-"}</div>
            <div className="table__item">{order.orderId}</div>
            <div className="table__item">
              {order.amount} {order.customerCurrency.code}
            </div>
            <div className="table__item">
              {order.to} {order.receiverCurrency.code}
            </div>
            <div className="table__item">
              <div
                className={`table__item-status table__item-status--${
                  order.status === 6
                    ? "green"
                    : order.status === 8 || order.status === 9
                    ? "red"
                    : "orange"
                }`}
              >
                {getOrderStatusLabel(order.status)}
              </div>
            </div>
            <div className="table__item">
              <div className="table__item-actions">
                <button
                  className="table__item-btn button"
                  onClick={() => handleOpenChatModal(order.orderId, order)} // Открываем модальное окно с ticketId
                >
                  Чат
                </button>
                <button className="table__item-btn button button--blue-border">
                  Возврат по гарантии
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {isError && <div>Ошибка при загрузке данных.</div>}

      {/* Модальное окно чата */}
      <Modal
        title={`Чат поддержки по ордеру ${ticketId}`}
        isOpen={isChatModalOpen}
        onClose={handleCloseChatModal}
      >
        {ticketId && <OrderChatModal ticketId={ticketId} order={order} />}
      </Modal>
    </main>
  );
};

export default History;
