import React, { useEffect, useState, useCallback } from "react";
import { useGetMe } from "../../services/user.services";
import { io } from "socket.io-client";
import { useGetMessages, useReadMessage } from "../../services/chat.services";
import { format } from "date-fns";
import { useResolveDispute } from "../../services/orders.services";
import toast from "react-hot-toast";

interface IProps {
  ticketId?: number;
  onClose?: () => void;
}

interface Message {
  id: number;
  text: string;
  sender: "user" | "support";
  time: string;
  date: string;
  read?: boolean;
  senderRole?: string;
  receiverRole?: string;
}

const SOCKET_URL = "https://bobster.freeblock.site/websocket";

const ChatModal: React.FC<IProps> = ({ ticketId, onClose }) => {
  const [socket, setSocket] = useState<any>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState("");

  const { me } = useGetMe();
  const { messages: messageList } = useGetMessages(ticketId!, true);
  const { mutate: readMessage } = useReadMessage();
  const { mutate: resolveDispute } = useResolveDispute(
    () => {
      toast.success("Dispute resolved successfully");
      onClose?.();
    },
    (error) => {
      toast.error(error.response.data.message);
    }
  );
  const token = localStorage.getItem("token");

  // Обновляем эффект загрузки сообщений
  useEffect(() => {
    if (messageList && messageList.length > 0 && me) {
      const loadedMessages = messageList.map((msg: any) => ({
        id: msg.chatMessage_id,
        text: msg.chatMessage_message,
        sender: msg.chatMessage_senderId === me?.id ? "user" : "support",
        time: new Date(msg.chatMessage_createdAt).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        date: msg.chatMessage_createdAt.split("T")[0],
        read: msg.chatMessage_read,
        senderRole: msg.senderRole.name,
        receiverRole: msg.receiverRole.name,
      }));

      setMessages(loadedMessages);

      // Проверяем и отмечаем непрочитанные сообщения только при начальной загрузке
      const unreadMessages = loadedMessages.filter(
        (msg: any) => msg.sender === "support" && !msg.read
      );

      if (unreadMessages.length > 0) {
        unreadMessages.forEach((message: any) => {
          readMessage(message.id.toString());
        });
      }
    }
  }, [messageList, me, readMessage]);

  // Подключение к WebSocket
  useEffect(() => {
    const newSocket = io(SOCKET_URL, {
      query: { token: token },
      transports: ["websocket"],
    });

    setSocket(newSocket);

    newSocket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    newSocket.on("message", (message: any) => {
      const newMessage = {
        id: message[0].id,
        text: message[0].message,
        sender: message[0].senderId === me?.id ? "user" : "support",
        time: new Date(message[0].createdAt).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        date: message[0].createdAt.split("T")[0],
        read: false,
      };

      setMessages((prevMessages: any) => [newMessage, ...prevMessages]);

      // Отмечаем новое сообщение от пользователя как прочитанное
      if (newMessage.sender === "user") {
        readMessage(newMessage.id.toString());
      }
    });

    newSocket.on("disconnect", () => {
      console.log("Disconnected from WebSocket server");
    });

    return () => {
      newSocket.disconnect();
    };
  }, [me, readMessage, token]);

  // Возвращаем handleSubmit в прежнее состояние
  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      if (inputValue.trim() && socket && me && messageList.length > 0) {
        const newMessage: Message = {
          id: Date.now(),
          text: inputValue,
          sender: "user",
          time: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          date: new Date().toISOString().split("T")[0],
          read: false,
        };

        // Найдем всех возможных получателей, исключая `me.id`, `0` и `1`
        const uniqueReceivers = Array.from(
          new Set(
            messageList
              .flatMap((item: any) => [
                item.chatMessage_receiverId,
                item.chatMessage_senderId,
              ])
              .filter(
                (id: number | undefined) =>
                  id !== undefined && id !== me.id && id !== 0 && id !== 1
              )
          )
        );

        // Ограничим список максимум двумя значениями
        const receiverId = uniqueReceivers.slice(0, 2);

        socket.emit("sendMessage", {
          senderId: me.id,
          receiverId: receiverId,
          ticketId: "support",
          message: inputValue,
          orderId: ticketId,
        });

        setMessages((prevMessages) => [newMessage, ...prevMessages]);
        setInputValue("");
      }
    },
    [inputValue, socket, me, messageList, ticketId]
  );

  // Отправка сообщения по нажатию на Enter
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSubmit(e);
      }
    },
    [handleSubmit]
  );

  // Группировка сообщений по месяцам
  const groupedMessages = messages.reduce<{ [key: string]: Message[] }>(
    (groups, message) => {
      const date = new Date(message.date);
      const monthYear = format(date, "dd MMMM yyyy");

      if (!groups[monthYear]) {
        groups[monthYear] = [];
      }
      groups[monthYear].push(message);
      return groups;
    },
    {}
  );

  // Сортируем ключи группировки по дате
  const sortedGroupKeys = Object.keys(groupedMessages).sort((a, b) => {
    return new Date(a).getTime() - new Date(b).getTime();
  });

  // Сортируем сообщения внутри каждой группы по дате
  sortedGroupKeys.forEach((key) => {
    groupedMessages[key].sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
  });

  return (
    <div className="create-admin">
      <div className="chat">
        <div className="chat-body">
          <div className="chat__items">
            {sortedGroupKeys.map((monthYear) => (
              <div key={monthYear}>
                <h2 className="chat__date">{monthYear}</h2>
                {groupedMessages[monthYear].reverse().map((message) => (
                  <div key={message.id} className="chat__messages">
                    <div
                      className={`chat__message chat__message--${message.sender}`}
                    >
                      <div
                        className={`senderRole${
                          message.senderRole === "SuperAdmin" ||
                          message.senderRole === "Admin"
                            ? "--admin"
                            : ""
                        }`}
                      >
                        {message.senderRole}
                      </div>
                      <div className="chat__message-text">{message.text}</div>
                      <div className="chat__message-time">
                        {message.time}
                        {message.sender === "user" && (
                          <div className="chat__message-check">
                            {message.read ? (
                              <img
                                src="/img/svg/check-mes2.svg"
                                className={`chat__message-check-icon`}
                                alt="read"
                              />
                            ) : (
                              <img
                                className={`chat__message-check-icon ${
                                  message.read ? "read" : ""
                                }`}
                                src="/img/svg/check-mes.svg"
                                alt=""
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <form className="chat__input-container" onSubmit={handleSubmit}>
          <button type="button" className="chat__input-btn">
            <img
              className="chat__input-icon chat__input-icon--emoji"
              src="/img/svg/smile.svg"
              alt=""
            />
          </button>
          <textarea
            className="chat__input"
            placeholder="Start typing..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            rows={1}
          />
          <button type="button" className="chat__input-btn">
            <img
              className="chat__input-icon chat__input-icon--mention"
              src="/img/svg/mention.svg"
              alt=""
            />
          </button>
          <button type="submit" className="chat__input-btn">
            <svg className="chat__input-icon chat__input-icon--send">
              <use xlinkHref="/img/sprite.svg#send"></use>
            </svg>
          </button>
        </form>
        <button
          type="button"
          className="users__btn-create button"
          style={{
            fontSize: 14,
            padding: 10,
            width: "100%",
            minWidth: "100%",
          }}
          onClick={() => resolveDispute(ticketId!)}
        >
          Вопрос решен
        </button>
      </div>
    </div>
  );
};

export default ChatModal;
