import React from "react";

const Header: React.FC = () => {
  return (
    <header className="header">
      <form action="#" className="header__search form">
        <div className="form__item">
          <div className="form-control form-control--search">
            <button className="form-control__btn ">
              <svg className="form-control__icon">
                <use xlinkHref="/img/sprite.svg#search"></use>
              </svg>
            </button>
            <input
              className="form-control__input"
              type="text"
              value=""
              placeholder="Type here..."
            />
          </div>
        </div>
      </form>
      <a href="/" className="header__out">
        <img src="/img/svg/out.svg" alt="" />
      </a>
    </header>
  );
};

export default Header;
