import React from "react";
import { useGetMe } from "../../services/user.services";

interface UserFilterProps {
  filter: { roles: { id: number }[] };
  setFilter: (newFilter: { roles: { id: number }[] }) => void;
  toggleNewAdminModal: () => void;
  toggleNewReferrerModal: () => void;
  toggleNewTrader2Modal: () => void;
}

const UserFilter: React.FC<UserFilterProps> = ({
  filter,
  setFilter,
  toggleNewAdminModal,
  toggleNewReferrerModal,
  toggleNewTrader2Modal,
}) => {
  const { me } = useGetMe();

  const filterOptions = [
    { id: "customers", label: "Клиенты", roleId: 3 },
    { id: "traders", label: "Трейдеры", roleId: 4 },
    { id: "trader2", label: "Посредники", roleId: 5 },
    { id: "referrer", label: "Реферреры", roleId: 2 },
    { id: "admin", label: "Администраторы", roleId: 1 },
  ];

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target;
    const selectedOption = filterOptions.find((option) => option.id === id);
    if (selectedOption) {
      setFilter({ roles: [{ id: selectedOption.roleId }] });
    }
  };

  return (
    <div className="users__actions">
      <div className="users__filter filter">
        {filterOptions.map((option) => (
          <div key={option.id} className="filter__item">
            <input
              type="radio"
              name="userType"
              id={option.id}
              className="filter__input"
              checked={filter.roles[0]?.id === option.roleId}
              onChange={handleFilterChange}
            />
            <label htmlFor={option.id} className="filter__label">
              {option.label}
            </label>
          </div>
        ))}
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        {me?.role.name === "SuperAdmin" && (
          <button
            onClick={toggleNewAdminModal}
            className="users__btn-create button"
            style={{
              fontSize: 12,
              padding: 10,
              height: "auto",
              width: "auto",
              minWidth: "auto",
            }}
          >
            Создать
            <br /> Администратора
          </button>
        )}
        {(me?.role.name === "Admin" || me?.role.name === "SuperAdmin") && (
          <button
            onClick={toggleNewReferrerModal}
            className="users__btn-create button"
            style={{
              marginLeft: 10,
              fontSize: 12,
              padding: 10,
              height: "auto",
              width: "auto",
              minWidth: "auto",
            }}
          >
            Создать
            <br /> реферрера
          </button>
        )}
        {(me?.role.name === "Admin" || me?.role.name === "SuperAdmin") && (
          <button
            onClick={toggleNewTrader2Modal}
            className="users__btn-create button"
            style={{
              marginLeft: 10,
              fontSize: 12,
              padding: 10,
              height: "auto",
              width: "auto",
              minWidth: "auto",
            }}
          >
            Создать
            <br /> трейдера 2
          </button>
        )}
      </div>
    </div>
  );
};

export default UserFilter;
