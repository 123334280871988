import React, { useState } from "react";
import * as yup from "yup";
import Modal from "../../components/Modal";
import Loading from "../../components/Loading";
import {
  useAddCountry,
  useDeleteCountry,
  useGetCountries,
} from "../../services/addresses.services";
import NewCountryModal from "./NewCountryModal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";

const Countries: React.FC = () => {
  const [isNewCountryModalOpen, setIsNewCountryModalOpen] =
    useState<boolean>(false);

  const NewCountryFormSchema = yup.object().shape({
    country: yup
      .string()
      .required("Название страны является обязательным полем!"),
  });

  const useNewCountryReturForm = useForm({
    resolver: yupResolver(NewCountryFormSchema),
  });

  const { reset } = useNewCountryReturForm;

  const { countries, refetch, isLoading } = useGetCountries();

  const { mutate: addCountry } = useAddCountry(
    () => {
      setIsNewCountryModalOpen(false);
      toast.success("Страна успешно добавлена!");
      reset();
      refetch();
    },
    () => {
      toast.error("Что-то пошло не так!");
    }
  );

  const { mutate: deleteCountry } = useDeleteCountry(
    () => {
      refetch();
      toast.success("Страна успешно удалена!");
    },
    () => {
      toast.error("Что-то пошло не так!");
    }
  );

  const handleSubmitNewCountryForm = (data: { country: string }) => {
    addCountry(data.country);
  };

  const handleDeleteCountry = (id: string) => {
    deleteCountry(id);
  };

  return (
    <main className="main exchange">
      {isLoading && <Loading />}
      <div className="exchange__header">
        <h1 className="exchange__title title">Доступные страны</h1>
        <button
          onClick={() => setIsNewCountryModalOpen(true)}
          className="exchange__button button button--top"
        >
          Добавить новую страну
        </button>
      </div>

      <div className="exchange__table table-exchange table">
        <div className="table-exchange__items table__items table__items--head">
          <div className="table__item">ID</div>
          <div className="table__item">Название</div>
          <div className="table__item"></div>
          <div className="table__item"></div>
          <div className="table__item"></div>
        </div>
        {countries?.map((country: { id: string; country: string }) => (
          <div className="table-exchange__items table__items">
            <div className="table__item">{country?.id}</div>
            <div className="table__item">{country?.country}</div>
            <div className="table__item"></div>
            <div className="table__item"></div>
            <div className="table__item">
              <div className="table__item-action">
                <button
                  onClick={() => handleDeleteCountry(country?.id)}
                  className="table__item-img"
                >
                  <img
                    className="table__item-icon"
                    src="/img/svg/del.svg"
                    alt="icon"
                  />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal
        title="Новая страна"
        isOpen={isNewCountryModalOpen}
        onClose={() => setIsNewCountryModalOpen(false)}
      >
        <NewCountryModal
          returnFormInstance={useNewCountryReturForm}
          onSubmit={handleSubmitNewCountryForm}
        />
      </Modal>
    </main>
  );
};

export default Countries;
