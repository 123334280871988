import { useMutation, useQuery } from "@tanstack/react-query";
import authApi from "../config/authApi.config";
import { ICurrency, ICurrencyRequestData } from "../models/currency.models";

const getCurrencyList = async () => {
  return authApi
    .get("/currencies")
    .then((res) => res.data as ICurrency[])
    .catch((error) => {
      throw new Error(error);
    });
};

const addCurrency = async (data: ICurrencyRequestData) => {
  return authApi
    .post("/currencies", data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useGetCurrencyList = () => {
  const {
    data: currencyList,
    isLoading,
    isError,
    refetch
  } = useQuery({
    queryKey: ["CurrencyList"],
    queryFn: getCurrencyList,
  });

  return {
    currencyList,
    isLoading,
    isError,
    refetch
  };
};

export const useAddCurrency = (
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["NewCurrency"],
    mutationFn: (data: ICurrencyRequestData) => addCurrency(data),
    onSuccess,
    onError,
  });
};

const updateCurrency = async (data: ICurrency) => {
  return authApi
    .patch("/currencies", data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useUpdateCurrency = (
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["UpdateCurrency"],
    mutationFn: (data: ICurrency) => updateCurrency(data),
    onSuccess,
    onError,
  });
};

const deleteCurrency = async (id: string) => {
  return authApi
    .delete(`/currencies/${id}`)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useDeleteCurrency = (
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["DeleteCurrency"],
    mutationFn: (id: string) => deleteCurrency(id),
    onSuccess,
    onError,
  });
};

const getCurrencyById = async (id: string) => {
  return authApi
    .get(`/currencies/${id}`)
    .then((res) => res.data as ICurrency)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useGetCurrencyById = (id: string) => {
  const {
    data: currency,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["CurrencyById", id],
    queryFn: () => getCurrencyById(id),
    enabled: !!id, // Запрос будет выполнен только если передан id
  });

  return {
    currency,
    isLoading,
    isError,
  };
};
