import axios, { AxiosInstance } from 'axios';

const authApi: AxiosInstance = axios.create({
  baseURL: 'https://bobster.freeblock.site/api/v1',
});

let isRefreshing = false;
let refreshSubscribers: Array<(token: string) => void> = [];

// Обновляем токен для всех подписчиков после успешного рефреша
const onRrefreshed = (token: string) => {
  refreshSubscribers.map((callback) => callback(token));
  refreshSubscribers = [];
};

// Добавляем interceptor для перехвата ошибок 401
authApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const refreshToken = localStorage.getItem('refreshToken');
          const response = await authApi.post('/auth/refresh', null, {
            headers: { Authorization: `Bearer ${refreshToken}` },
          });

          const { token, refreshToken: newRefreshToken } = response.data;

          localStorage.setItem('token', token);
          localStorage.setItem('refreshToken', newRefreshToken);

          authApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;

          isRefreshing = false;
          onRrefreshed(token);

          return authApi(originalRequest);
        } catch (refreshError) {
          console.error('Refresh token failed', refreshError);
          isRefreshing = false;
          return Promise.reject(refreshError);
        }
      }

      return new Promise((resolve) => {
        refreshSubscribers.push((token: string) => {
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          resolve(authApi(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  }
);

export const setAuthToken = (token: string | null) => {
  if (token) {
    authApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete authApi.defaults.headers.common['Authorization'];
  }
};

export default authApi;
