import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { ICurrencyRequestData } from "../../models/currency.models";

interface IProps {
  returnFormInstance: UseFormReturn<ICurrencyRequestData>;
  onSubmit: (data: ICurrencyRequestData) => void;
}

const AddCurrencyModal: React.FC<IProps> = ({
  returnFormInstance,
  onSubmit,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = returnFormInstance;

  return (
    <div className="create-admin">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="create-admin__form form"
      >
        <Controller
          name="code"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="code" className="form__label">
                Код валюты
              </label>
              <input
                id="code"
                className="form-control"
                type="text"
                placeholder="USD"
                value={value}
                onChange={onChange}
                maxLength={10}
              />
              {errors.code?.message && (
                <p className="error">{errors.code?.message}</p>
              )}
            </div>
          )}
        />

        <Controller
          name="name"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="name" className="form__label">
                Название
              </label>
              <input
                id="name"
                className="form-control"
                type="text"
                placeholder="United States Dollar"
                value={value}
                onChange={onChange}
                maxLength={60}
              />
              {errors.name?.message && (
                <p className="error">{errors.name?.message}</p>
              )}
            </div>
          )}
        />

        <Controller
          name="symbol"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="symbol" className="form__label">
                Символ
              </label>
              <input
                id="symbol"
                className="form-control"
                type="text"
                placeholder="$"
                value={value}
                onChange={onChange}
                maxLength={10}
              />
              {errors.symbol?.message && (
                <p className="error">{errors.symbol?.message}</p>
              )}
            </div>
          )}
        />

        <Controller
          name="limit"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="limit" className="form__label">
                Лимит
              </label>
              <input
                id="limit"
                className="form-control"
                type="number"
                placeholder="1000"
                value={value}
                onChange={onChange}
                maxLength={12}
              />
              {errors.limit?.message && (
                <p className="error">{errors.limit?.message}</p>
              )}
            </div>
          )}
        />

        <button
          type="submit"
          className="create-admin__button button"
          data-animation="fadeInUp"
        >
          Добавить
        </button>
      </form>
    </div>
  );
};

export default AddCurrencyModal;
