import { useMutation, useQuery } from "@tanstack/react-query";
import authApi from "../config/authApi.config";
import {
  IAddCurrencyPairRequestData,
  ICurrencyPair,
  IUpdateCurrencyPairRequestData,
} from "../models/currency-pairs.models";

// POST: Добавление новой валютной пары
const addCurrencyPair = async (data: IAddCurrencyPairRequestData) => {
  return authApi
    .post("/currency-pairs", data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useAddCurrencyPair = (
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["AddCurrencyPair"],
    mutationFn: (data: IAddCurrencyPairRequestData) => addCurrencyPair(data),
    onSuccess,
    onError,
  });
};

// PATCH: Обновление валютной пары
const updateCurrencyPair = async (data: IUpdateCurrencyPairRequestData) => {
  return authApi
    .patch("/currency-pairs", data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useUpdateCurrencyPair = (
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["UpdateCurrencyPair"],
    mutationFn: (data: IUpdateCurrencyPairRequestData) =>
      updateCurrencyPair(data),
    onSuccess,
    onError,
  });
};

// GET: Получение списка всех валютных пар
const getCurrencyPairs = async () => {
  return authApi
    .get("/currency-pairs")
    .then((res) => res.data as ICurrencyPair[])
    .catch((error) => {
      throw new Error(error);
    });
};

export const useGetCurrencyPairs = () => {
  const {
    data: currencyPairs,
    isLoading,
    isError,
    refetch
  } = useQuery({
    queryKey: ["CurrencyPairs"],
    queryFn: getCurrencyPairs,
  });

  return {
    currencyPairs,
    isLoading,
    isError,
    refetch
  };
};

// GET: Получение обменного курса между двумя валютами
const getExchangeRate = async (id1: string, id2: string) => {
  return authApi
    .get(`/currency-pairs/exchange-rate/${id1}/${id2}`)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useGetExchangeRate = (id1: string, id2: string) => {
  const {
    data: exchangeRate,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["ExchangeRate", id1, id2],
    queryFn: () => getExchangeRate(id1, id2),
    enabled: !!id1 && !!id2, // Запрос выполняется только если оба id заданы
  });

  return {
    exchangeRate,
    isLoading,
    isError,
  };
};

// DELETE: Удаление валютной пары по id
const deleteCurrencyPair = async (id: string) => {
  return authApi
    .delete(`/currency-pairs/${id}`)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useDeleteCurrencyPair = (
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["DeleteCurrencyPair"],
    mutationFn: (id: string) => deleteCurrencyPair(id),
    onSuccess,
    onError,
  });
};
