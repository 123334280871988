import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../../components/Form/Input";
import { Controller, useForm } from "react-hook-form";
import { useRegistration } from "../../../services/auth.services";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { ROUTES } from "../../../routes/routes";

const Registration: React.FC = () => {
  const navigate = useNavigate();

  const { mutate: registration } = useRegistration(
    (data) => {
      console.log(data);
      toast.success("Добро пожаловать!");
      navigate(ROUTES.MAIN);
    },
    (error) => {
      console.log(error);
      toast.error("Неверный логин или пароль!");
    }
  );

  const LoginFormSchema = yup.object().shape({
    email: yup
      .string()
      .email("Поле должно быть в формате электронной почты!")
      .required("Введите почту!"),
    password: yup.string().required("Введите пароль!"),
  });

  const returnForm = useForm({
    resolver: yupResolver(LoginFormSchema),
  });

  const handleSubmitForm = (data: { email: string; password: string }) => {
    registration(data);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = returnForm;

  return (
    <main className="admin-login__wrapper">
      <form className="admin-login__form-container">
        <div className="admin-login__form-logo">
          <div
            className="logo"
            style={{
              justifyContent: "center",
            }}
          >
            <img
              className="logo__icon"
              src="/img/logo.svg"
              alt="logo bobster"
            />
            <div className="logo__text">
              <div className="logo__name">BOBSTER</div>
              <div className="logo__descr">PAYMENTS</div>
            </div>
          </div>
        </div>

        <h1 className="admin-login__form-title">Регистрация</h1>

        <Controller
          name="email"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              id="email"
              label="Email"
              type="email"
              placeholder="Введите Вашу почту"
              value={value}
              onChange={onChange}
              error={Boolean(errors.email?.message)}
              helperText={errors.email?.message}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              id="password"
              label="Пароль"
              type="password"
              placeholder="Введите Ваш пароль"
              value={value}
              onChange={onChange}
              error={Boolean(errors.password?.message)}
              helperText={errors.password?.message}
            />
          )}
        />

        <button
          className="create-admin__button button"
          style={{
            width: "100%",
          }}
          data-animation="fadeInUp"
          onClick={handleSubmit(handleSubmitForm)}
        >
          Зарегистрироваться
        </button>
      </form>
    </main>
  );
};

export default Registration;
