import React, { useState, useEffect } from "react";
import { useGetOrderHistory } from "../../services/orders.services";
import Loading from "../../components/Loading";

const FILTER_OPTIONS = [
  { id: "dispute", label: "Споры" },
  { id: "in_process", label: "В процессе" },
  { id: "completed", label: "Исполнен" },
  { id: "trader2", label: "Trader 2" },
  { id: "direct", label: "Прямые" },
  { id: "cash", label: "Наличные" },
];

const History: React.FC = () => {
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [keyword, setKeyword] = useState(""); // для поиска по ключевому слову
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]); // выбранные фильтры
  const [sort, setSort] = useState("");

  // Получаем список транзакций через кастомный хук
  const { orders, isLoading, isError } = useGetOrderHistory({
    page,
    limit,
    keyword,
    statuses: selectedFilters.includes("dispute") ? [2] : [], // Пример использования фильтра статусов
    orderTypes: selectedFilters.includes("direct") ? [1] : [],
    isCash: selectedFilters.includes("cash") ? true : null, // Пример фильтра по наличным
    sort,
  });

  // Обработчик для подгрузки данных при скролле
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return;
    setPage((prevPage) => prevPage + 1); // Переход на следующую страницу при прокрутке вниз
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Обработчик изменения фильтров
  const handleFilterChange = (filter: string) => {
    setSelectedFilters((prevFilters) =>
      prevFilters.includes(filter)
        ? prevFilters.filter((f) => f !== filter) // Удалить фильтр, если он был выбран
        : [...prevFilters, filter] // Добавить фильтр
    );
  };

  return (
    <main className="main history">
      {isLoading && <Loading />}
      <h1 className="history__title title">История Транзакций</h1>

      {/* Фильтры */}
      <div className="history__filter filter">
        {FILTER_OPTIONS.map((option) => (
          <div key={option.id} className="filter__item">
            <input
              id={option.id}
              type="checkbox"
              className="filter__input"
              checked={selectedFilters.includes(option.id)}
              onChange={() => handleFilterChange(option.id)}
            />
            <label htmlFor={option.id} className="filter__label">
              {option.label}
            </label>
          </div>
        ))}
      </div>

      {/* Таблица с данными */}
      <div className="history__table table-history table">
        <div className="table-history__items table__items table__items--head">
          <div className="table__item">Customer ID</div>
          <div className="table__item">Trader 1 ID</div>
          <div className="table__item">Trader 2 ID</div>
          <div className="table__item">Order ID</div>
          <div className="table__item">From</div>
          <div className="table__item">To</div>
          <div className="table__item">Status</div>
          <div className="table__item">Action</div>
        </div>
        {orders?.map((order: any) => (
          <div key={order.id} className="table-history__items table__items">
            <div className="table__item table__item--id">{order.customerId}</div>
            <div className="table__item">{order.trader1Id}</div>
            <div className="table__item">{order.trader2Id || "-"}</div>
            <div className="table__item">{order.orderId}</div>
            <div className="table__item">{order.from}</div>
            <div className="table__item">{order.to}</div>
            <div className="table__item">
              <div
                className={`table__item-status table__item-status--${
                  order.status === "COMPLETED"
                    ? "green"
                    : order.status === "CANCELED"
                    ? "red"
                    : "orange"
                }`}
              >
                {order.status}
              </div>
            </div>
            <div className="table__item">
              <div className="table__item-actions">
                <button className="table__item-btn button">Чат</button>
                <button className="table__item-btn button button--blue-border">
                  Возврат по гарантии
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {isError && <div>Ошибка при загрузке данных.</div>}
    </main>
  );
};

export default History;
