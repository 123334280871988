import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <p className="footer__copy">@ 2024. All rights reserved</p>
      <div className="footer__menu menu">
        <nav className="menu__body">
          <ul className="menu__list">
            <li className="menu__item">
              <a href="/" className="menu__link">
                Privacy Policy
              </a>
            </li>
            <li className="menu__item">
              <a href="/" className="menu__link">
                Terms
              </a>
            </li>
            <li className="menu__item">
              <a href="/" className="menu__link">
                Blog
              </a>
            </li>
            <li className="menu__item">
              <a href="/" className="menu__link">
                Contacts
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
