import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";

interface IProps {
  returnFormInstance: UseFormReturn<{
    email: string;
    password: string;
    repeatPassword: string;
  }>;
  onSubmit: (data: {
    email: string;
    password: string;
    repeatPassword: string;
  }) => void;
}

const NewAdminModal: React.FC<IProps> = ({ returnFormInstance, onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = returnFormInstance;

  return (
    <div className="create-admin">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="create-admin__form form"
      >
        <Controller
          name="email"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="email" className="form__label">
                Email
              </label>
              <input
                id="email"
                className="form-control"
                type="email"
                placeholder="ivan_ivanov@gmail.com"
                value={value}
                onChange={onChange}
              />
              {errors.email?.message && (
                <p className="error">{errors.email?.message}</p>
              )}
            </div>
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="password" className="form__label">
                Пароль
              </label>
              <input
                id="password"
                className="form-control"
                type="password"
                placeholder="**************"
                value={value}
                onChange={onChange}
              />
              {errors.email?.message && (
                <p className="error">{errors.password?.message}</p>
              )}
            </div>
          )}
        />

        <Controller
          name="repeatPassword"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="repeatPassword" className="form__label">
                Повторите Пароль
              </label>
              <input
                id="repeatPassword"
                className="form-control"
                type="password"
                placeholder="**************"
                value={value}
                onChange={onChange}
              />
              {errors.email?.message && (
                <p className="error">{errors.repeatPassword?.message}</p>
              )}
            </div>
          )}
        />
        <button
          className="create-admin__button button"
          data-animation="fadeInUp"
        >
          Добавить
        </button>
      </form>
    </div>
  );
};

export default NewAdminModal;
