import React, { useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import axios from "axios";
import authApi from "../../config/authApi.config";

interface IProps {
  returnFormInstance: UseFormReturn<{
    name: string;
    code: string;
    file_path: string;
  }>;
  onSubmit: (data: { name: string; code: string; file_path: string }) => void;
}

const NewLanguageModal: React.FC<IProps> = ({
  returnFormInstance,
  onSubmit,
}) => {
  const [uploadError, setUploadError] = useState<string | null>(null);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = returnFormInstance;

  const handleFileUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await authApi.post("/languages/uploadJSON", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setValue("file_path", response.data.file);
      setUploadError(null);
    } catch (error) {
      setUploadError("Ошибка загрузки файла. Попробуйте снова.");
      console.error("File upload error:", error);
    }
  };

  return (
    <div className="create-admin">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="create-admin__form form"
      >
        {/* Language Code */}
        <Controller
          name="code"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="code" className="form__label">
                Код языка
              </label>
              <input
                id="code"
                className="form-control"
                type="text"
                placeholder="Введите код языка"
                value={value}
                onChange={onChange}
              />
              {errors.code?.message && (
                <p className="error">{errors.code?.message}</p>
              )}
            </div>
          )}
        />

        {/* Language Name */}
        <Controller
          name="name"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="name" className="form__label">
                Название языка
              </label>
              <input
                id="name"
                className="form-control"
                type="text"
                placeholder="Введите название языка"
                value={value}
                onChange={onChange}
              />
              {errors.name?.message && (
                <p className="error">{errors.name?.message}</p>
              )}
            </div>
          )}
        />

        <Controller
          name="file_path"
          control={control}
          render={({ field: { value } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="file" className="form__label">
                Языковой пакет (JSON)
              </label>
              <input
                id="file"
                className="form-control"
                type="file"
                accept="application/json"
                onChange={(e) => {
                  if (e.target.files?.[0]) {
                    handleFileUpload(e.target.files[0]);
                  }
                }}
              />
              {uploadError && <p className="error">{uploadError}</p>}
              {value && <p className="success">Файл загружен: {value}</p>}
            </div>
          )}
        />

        <button
          type="submit"
          className="create-admin__button button"
          data-animation="fadeInUp"
        >
          Добавить
        </button>
      </form>
    </div>
  );
};

export default NewLanguageModal;
