import React from "react";
import { useGetMe } from "../../services/user.services";

interface UserFilterProps {
  filter: { roles: { id: number }[] };
  setFilter: (newFilter: { roles: { id: number }[] }) => void; // Изменено для передачи фильтра наверх
  toggleNewAdminModal: () => void;
  toggleNewReferrerModal: () => void;
}

const UserFilter: React.FC<UserFilterProps> = ({
  filter,
  setFilter,
  toggleNewAdminModal,
  toggleNewReferrerModal,
}) => {
  const { me } = useGetMe();

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target;
    let roleId: number;

    switch (id) {
      case "customers":
        roleId = 3;
        break;
      case "traders":
        roleId = 4;
        break;
      case "trader2":
        roleId = 5;
        break;
      case "referrer":
        roleId = 2;
        break;
      case "admin":
        roleId = 1;
        break;
      default:
        roleId = 3;
    }

    setFilter({ roles: [{ id: roleId }] }); // Обновляем фильтр
  };

  return (
    <div className="users__actions">
      <div className="users__filter filter">
        <div className="filter__item">
          <input
            type="radio"
            name="userType"
            id="customers"
            className="filter__input"
            checked={filter.roles[0]?.id === 3}
            onChange={handleFilterChange}
          />
          <label htmlFor="customers" className="filter__label">
            Customers
          </label>
        </div>
        <div className="filter__item">
          <input
            type="radio"
            name="userType"
            id="traders"
            className="filter__input"
            checked={filter.roles[0]?.id === 4}
            onChange={handleFilterChange}
          />
          <label htmlFor="traders" className="filter__label">
            Traders
          </label>
        </div>
        <div className="filter__item">
          <input
            type="radio"
            name="userType"
            id="trader2"
            className="filter__input"
            checked={filter.roles[0]?.id === 5}
            onChange={handleFilterChange}
          />
          <label htmlFor="trader2" className="filter__label">
            Trader 2
          </label>
        </div>
        <div className="filter__item">
          <input
            type="radio"
            name="userType"
            id="referrer"
            className="filter__input"
            checked={filter.roles[0]?.id === 2}
            onChange={handleFilterChange}
          />
          <label htmlFor="referrer" className="filter__label">
            Referrer
          </label>
        </div>
        <div className="filter__item">
          <input
            type="radio"
            name="userType"
            id="admin"
            className="filter__input"
            checked={filter.roles[0]?.id === 1}
            onChange={handleFilterChange}
          />
          <label htmlFor="admin" className="filter__label">
            Admin
          </label>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        {me?.role.name === "SuperAdmin" && (
          <button
            onClick={toggleNewAdminModal}
            className="users__btn-create button"
          >
            Создать Администратора
          </button>
        )}
        {(me?.role.name === "Admin" || me?.role.name === "SuperAdmin") && (
          <button
            onClick={toggleNewReferrerModal}
            className="users__btn-create button"
            style={{
              marginLeft: 10
            }}
          >
            Создать реферрера
          </button>
        )}
      </div>
    </div>
  );
};

export default UserFilter;
