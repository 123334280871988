import React from "react";
import { useGetAddresses } from "../../services/addresses.services";

const Addresses: React.FC = () => {
  const { addresses } = useGetAddresses();

  console.log(addresses);
  return (
    <main className="main addresses">
      <h1 className="addresses__title title">Мои адреса выдачи cash</h1>

      <div className="addresses__table table-addresses table">
        <div className="table-addresses__items table__items table__items--head">
          <div className="table__item">Страна</div>
          <div className="table__item">Город</div>
          <div className="table__item">Адрес</div>
          <div className="table__item">Валюта</div>
        </div>
        <div className="table-addresses__items table__items">
          <div className="table__item">Турция</div>
          <div className="table__item">Стамбул</div>
          <div className="table__item">ул.Бурака Йлмаза 1а/ оф. 38</div>
          <div className="table__item">TRY</div>
        </div>
        <div className="table-addresses__items table__items">
          <div className="table__item">Турция</div>
          <div className="table__item">Стамбул</div>
          <div className="table__item">ул.Бурака Йлмаза 1а/ оф. 38</div>
          <div className="table__item">TRY</div>
        </div>
        <div className="table-addresses__items table__items">
          <div className="table__item">Турция</div>
          <div className="table__item">Стамбул</div>
          <div className="table__item">ул.Бурака Йлмаза 1а/ оф. 38</div>
          <div className="table__item">TRY</div>
        </div>
      </div>
    </main>
  );
};

export default Addresses;
