import { useMutation, useQuery } from "@tanstack/react-query";
import authApi from "../config/authApi.config";

const getOrderHistory = async ({
  page = 1,
  limit = 10,
  keyword = "",
  statuses = [],
  orderTypes = [],
  isCash = null,
  sort = "",
}: {
  page: number;
  limit: number;
  keyword?: string;
  statuses?: number[];
  orderTypes?: number[];
  isCash?: boolean | null;
  sort?: string;
}) => {
  // Формируем параметры запроса
  const params = new URLSearchParams();

  params.append("page", page.toString());
  params.append("limit", limit.toString());

  if (keyword) {
    params.append("keyword", keyword);
  }

  // Добавляем статусы и типы заказов как массив
  statuses.forEach((status) => params.append("statuses", status.toString()));
  orderTypes.forEach((type) => {
    params.append("orderTypes", type.toString());
    params.append("orderTypes", "1");
  });

  if (isCash !== null) {
    params.append("isCash", isCash.toString());
  }

  if (sort) {
    params.append("sort", sort);
  }

  return authApi
    .get(`/orders?${params.toString()}`)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const resolveDispute = async (orderId: number) => {
  return authApi
    .patch(`/orders/resolveDispute`, {
      orderId,
    })
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useGetOrderHistory = ({
  page = 1,
  limit = 10,
  keyword = "",
  statuses = [],
  orderTypes = [],
  isCash = null,
  sort = "",
}: {
  page: number;
  limit: number;
  keyword?: string;
  statuses?: number[];
  orderTypes?: number[];
  isCash?: boolean | null;
  sort?: string;
}) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [
      "OrderHistory",
      page,
      limit,
      keyword,
      statuses,
      orderTypes,
      isCash,
      sort,
    ],
    queryFn: () =>
      getOrderHistory({
        page,
        limit,
        keyword,
        statuses,
        orderTypes,
        isCash,
        sort,
      }),
  });

  const hasMore = data?.length === limit;

  return {
    orders: data || [],
    isLoading,
    isError,
    hasMore,
  };
};

export const useResolveDispute = (
  onSuccess?: () => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["resolveDispute"],
    mutationFn: resolveDispute,
    onSuccess,
    onError,
  });
};
