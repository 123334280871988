import React, { useState } from "react";
import * as yup from "yup";
import {
  useAddCurrency,
  useDeleteCurrency,
  useGetCurrencyList,
  useUpdateCurrency,
  useGetCurrencyById, // Импортируйте новый хук
} from "../../services/currencies.services";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ICurrencyRequestData } from "../../models/currency.models";
import Modal from "../../components/Modal";
import AddCurrencyModal from "./AddCurrencyModal";
import EditCurrencyModal from "./EditCurrencyModal"; // Импортируйте модуль для редактирования
import Loading from "../../components/Loading";

const Currencies: React.FC = () => {
  const [isAddCurrencyModalOpen, setIsAddCurrencyModalOpen] =
    useState<boolean>(false);
  const [isEditCurrencyModalOpen, setIsEditCurrencyModalOpen] =
    useState<boolean>(false);
  const [selectedCurrencyId, setSelectedCurrencyId] = useState<number | null>(
    null
  ); // Храним ID выбранной валюты
  const { currencyList, refetch, isLoading } = useGetCurrencyList();

  const { currency, isLoading: isLoadingCurrency } = useGetCurrencyById(
    selectedCurrencyId?.toString()!
  ); // Получаем данные валюты по ID

  const AddCurrencyFormSchema = yup.object().shape({
    code: yup.string().required("Код валюты является обязательным полем!"),
    name: yup
      .string()
      .required("Полное название валюты является обязательным полем!"),
    symbol: yup.string().required("Символ валюты является обязательным полем!"),
    limit: yup
      .number()
      .required("Лимит для валюты является обязательным полем!"),
  });

  const useAddReturnForm = useForm<ICurrencyRequestData>({
    resolver: yupResolver(AddCurrencyFormSchema),
  });

  const handleSubmitAddForm = (data: ICurrencyRequestData) => {
    addCurrency(data);
  };

  const { reset } = useAddReturnForm;

  const { mutate: addCurrency } = useAddCurrency(
    () => {
      toast.success("Валюта успешно добавлена!");
      setIsAddCurrencyModalOpen(false);
      reset();
      refetch();
    },
    () => toast.error("Что-то пошло не так...")
  );

  const { mutate: deleteCurrency } = useDeleteCurrency(
    () => refetch(),
    () => toast.error("Что-то пошло не так...")
  );

  const { mutate: updateCurrency } = useUpdateCurrency(
    () => {
      toast.success("Валюта успешно обновлена!");
      setIsEditCurrencyModalOpen(false);
      refetch();
    },
    () => toast.error("Что-то пошло не так...")
  );

  const handleDeleteCurrency = (id: number) => {
    deleteCurrency(id.toString());
  };

  const handleEditCurrency = (id: number) => {
    setSelectedCurrencyId(id); // Сохраняем ID валюты
    setIsEditCurrencyModalOpen(true); // Открываем модальное окно редактирования
  };

  return (
    <main className="main exchange">
      {isLoading && <Loading />}
      <div className="exchange__header">
        <h1 className="exchange__title title">Управление валютами</h1>
        <button
          onClick={() => setIsAddCurrencyModalOpen(true)}
          className="exchange__button button button--top"
        >
          Добавить валюту
        </button>
      </div>

      <div className="exchange__table table-exchange table">
        <div className="table-currency__items table__items table__items--head">
          <div className="table__item">Код</div>
          <div className="table__item">Название</div>
          <div className="table__item">Символ</div>
          <div className="table__item">Лимит</div>
        </div>
        {currencyList?.map((currency) => (
          <div
            key={currency?.id}
            className="table-currency__items table__items"
          >
            <div className="table__item">{currency?.code}</div>
            <div className="table__item">{currency?.name}</div>
            <div className="table__item">{currency?.symbol}</div>
            <div className="table__item">{currency?.limit}</div>
            <div className="table__item">
              <div className="table__item-action">
                <button
                  onClick={() => handleEditCurrency(currency?.id)} // Передаем ID валюты в обработчик редактирования
                  className="table__item-img"
                >
                  <img
                    className="table__item-icon"
                    src="/img/svg/edit.svg"
                    alt="icon"
                  />
                </button>
                <button
                  onClick={() => handleDeleteCurrency(currency?.id)}
                  className="table__item-img"
                >
                  <img
                    className="table__item-icon"
                    src="/img/svg/del.svg"
                    alt="icon"
                  />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal
        isOpen={isAddCurrencyModalOpen}
        onClose={() => setIsAddCurrencyModalOpen(false)}
        title="Добавить валюту"
      >
        <AddCurrencyModal
          returnFormInstance={useAddReturnForm}
          onSubmit={handleSubmitAddForm}
        />
      </Modal>
      <Modal
        isOpen={isEditCurrencyModalOpen}
        onClose={() => setIsEditCurrencyModalOpen(false)}
        title="Редактировать валюту"
      >
        {isLoadingCurrency ? ( // Загружаем данные валюты по ID
          <Loading />
        ) : (
          currency && (
            <EditCurrencyModal
              currency={currency} // Передаем валюту, полученную с сервера
              onSubmit={updateCurrency} // Функция для обновления валюты
            />
          )
        )}
      </Modal>
    </main>
  );
};

export default Currencies;
