import clsx from "clsx";
import React from "react";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: boolean;
  helperText?: string;
  className?: string;
}

const Input: React.FC<IProps> = ({
  label,
  error,
  helperText,
  className,
  ...props
}) => {
  return (
    <div className={clsx("create-admin__item form__item", className)}>
      <label htmlFor={props.id || "input"} className="form__label">
        {label}
      </label>
      <input
        className={clsx("form-control", {
          "form__item-error": error,
        })}
        {...props}
      />
      {error && <p className="error">{helperText}</p>}
    </div>
  );
};

export default Input;
