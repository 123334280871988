import React from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import {
  useGetDefaultRefererFee,
  useGetDefaultTraderFee,
  useSetFees,
} from "../../services/fees.services";
import toast from "react-hot-toast";

const schema = yup.object().shape({
  fee: yup
    .number()
    .typeError("Введите корректное значение комиссии")
    .min(0, "Комиссия не может быть меньше 0")
    .required("Комиссия обязательна"),
  fromDate: yup.string().when("isDeferred", {
    is: (isDeferred: boolean) => isDeferred === true,
    then: (schema) =>
      schema.required("Дата обязательна для отложенного запуска"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

interface FormData {
  fee: number;
  fromDate: string;
  isDeferredTrader: boolean;
  isDeferredReferrer: boolean;
}

const Fees: React.FC = () => {
  const { mutate } = useSetFees();
  const { defaultRefererFee, refetch: refetchReferer } =
    useGetDefaultRefererFee();
  const { defaultTraderFee, refetch: refetchTrader } = useGetDefaultTraderFee();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm<FormData>({
    // @ts-ignore
    resolver: yupResolver(schema),
    defaultValues: {
      fee: 0,
      fromDate: dayjs().format("YYYY-MM-DD"), // Текущая дата по умолчанию
      isDeferredTrader: false,
      isDeferredReferrer: false,
    },
  });

  const watchDeferredTrader = watch("isDeferredTrader");
  const watchDeferredReferrer = watch("isDeferredReferrer");

  const onSubmit = (data: FormData, userType: number) => {
    const isTrader = userType === 4;
    const deferredWatch = isTrader
      ? watchDeferredTrader
      : watchDeferredReferrer;

    const payload: {
      userId: number;
      userType: number;
      fee: number;
      fromDate?: string;
    } = {
      userId: 0,
      userType: userType,
      fee: data.fee,
    };

    if (deferredWatch) {
      payload.fromDate = data.fromDate;
    }

    mutate(payload, {
      onSuccess: () => {
        toast.success("Комиссия успешно обновлена");
        reset();
        refetchReferer();
        refetchTrader();
      },
      onError: (error: any) => {
        toast.error("Ошибка при обновлении комиссии:", error);
      },
    });
  };

  return (
    <main className="main fees">
      <h1 className="fees__title title">Коммиссии и начисления</h1>
      <div className="fees__items">
        <div className="fees__item">
          <h2 className="fees__item-title">Изменить общую комиссию трейдера</h2>
          <div className="fees__current">
            Текущее значение {defaultTraderFee}%
          </div>
          <form
            className="fees__form form"
            onSubmit={handleSubmit((data) => onSubmit(data, 4))}
          >
            <div className="form__items">
              <div className="form__item">
                <label htmlFor="fee" className="form__label">
                  Новое значение
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={defaultTraderFee}
                  {...register("fee")}
                />
                {errors.fee && <p className="error">{errors.fee.message}</p>}
              </div>

              {watchDeferredTrader && (
                <div className="form__item">
                  <label htmlFor="fromDate" className="form__label">
                    Дата и время начала действия
                  </label>
                  <input
                    className="form-control"
                    type="datetime-local"
                    {...register("fromDate")}
                  />
                  {errors.fromDate && (
                    <p className="error">{errors.fromDate.message}</p>
                  )}
                </div>
              )}
            </div>

            <Controller
              name="isDeferredTrader"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className="fees__checkbox checkbox">
                  <input
                    id="fees"
                    type="checkbox"
                    className="checkbox__input"
                    checked={value}
                    onChange={onChange}
                  />
                  <label htmlFor="fees" className="checkbox__label">
                    Отложенный запуск
                  </label>
                </div>
              )}
            />

            <button type="submit" className="button">
              Изменить
            </button>
          </form>
        </div>

        <div className="fees__item">
          <h2 className="fees__item-title">
            Изменить общее начисление реферера
          </h2>
          <div className="fees__current">
            Текущее значение {defaultRefererFee}%
          </div>
          <form
            className="fees__form form"
            onSubmit={handleSubmit((data) => onSubmit(data, 2))} // userType 2 для реферера
          >
            <div className="form__items">
              <div className="form__item">
                <label htmlFor="fee" className="form__label">
                  Новое значение
                </label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={defaultRefererFee}
                  {...register("fee")}
                />
                {errors.fee && <p className="error">{errors.fee.message}</p>}
              </div>

              {watchDeferredReferrer && (
                <div className="form__item">
                  <label htmlFor="fromDate" className="form__label">
                    Дата и время начала действия
                  </label>
                  <input
                    className="form-control"
                    type="datetime-local"
                    {...register("fromDate")}
                  />
                  {errors.fromDate && (
                    <p className="error">{errors.fromDate.message}</p>
                  )}
                </div>
              )}
            </div>

            <Controller
              name="isDeferredReferrer"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className="fees__checkbox checkbox">
                  <input
                    id="fees2"
                    type="checkbox"
                    className="checkbox__input"
                    checked={value}
                    onChange={onChange}
                  />
                  <label htmlFor="fees2" className="checkbox__label">
                    Отложенный запуск
                  </label>
                </div>
              )}
            />

            <button type="submit" className="button">
              Изменить
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default Fees;
