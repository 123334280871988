import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";

interface IProps {
  returnFormInstance: UseFormReturn<{
    tgUserName: string;
  }>;
  onSubmit: (data: { tgUserName: string }) => void;
}

const NewReferrerModal: React.FC<IProps> = ({
  returnFormInstance,
  onSubmit,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = returnFormInstance;

  const inputFieldProps = (
    name: string,
    placeholder: string,
    type: string = "text"
  ) => ({
    id: name,
    className: "form-control",
    type,
    placeholder,
  });

  return (
    <div className="create-admin">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="create-admin__form form"
      >
        <Controller
          name="tgUserName"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="telegramId" className="form__label">
                Telegram ID
              </label>
              <input
                {...inputFieldProps("telegramId", "Username")}
                value={value}
                onChange={onChange}
              />
              {errors.tgUserName?.message && (
                <p className="error">{errors.tgUserName?.message}</p>
              )}
            </div>
          )}
        />

        <button
          type="submit"
          className="create-admin__button button"
          data-animation="fadeInUp"
        >
          Добавить
        </button>
      </form>
    </div>
  );
};

export default NewReferrerModal;
