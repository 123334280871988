import { useMutation } from "@tanstack/react-query";
import api from "../config/api.config";
import { setAuthToken } from "../config/authApi.config";

const login = (data: { email: string; password: string }) => {
  return api
    .post("/auth/admin/login", {
      ...data,
    })
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const registration = (data: { email: string; password: string }) => {
  return api
    .post("/auth/admin/register", data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useLogin = (
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["Login"],
    mutationFn: (data: { email: string; password: string }) => login(data),
    onSuccess: (data) => {
      localStorage.setItem("token", data.token);
      localStorage.setItem("refreshToken", data.refreshToken);
      setAuthToken(data.token);

      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError,
  });
};

export const useRegistration = (
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["Registration"],
    mutationFn: (data: { email: string; password: string }) =>
      registration(data),
    onSuccess,
    onError,
  });
};
