import React from "react";
import { useNavigate } from "react-router";
import { ROUTES } from "../../routes/routes";
import toast from "react-hot-toast";

const Header: React.FC = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    toast.success("Вы успешно вышли из аккаунта!");
    navigate(ROUTES.LOGIN);
  };

  return (
    <header className="header">
      <form action="#" className="header__search form">
        <div className="form__item">
          <div className="form-control form-control--search">
            <button className="form-control__btn ">
              <svg className="form-control__icon">
                <use xlinkHref="/img/sprite.svg#search"></use>
              </svg>
            </button>
            <input
              className="form-control__input"
              type="text"
              value=""
              placeholder="Type here..."
            />
          </div>
        </div>
      </form>
      <button onClick={handleLogout} className="header__out">
        <img src="/img/svg/out.svg" alt="" />
      </button>
    </header>
  );
};

export default Header;
