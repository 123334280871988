import { useMutation, useQuery } from "@tanstack/react-query";
import authApi from "../config/authApi.config";
import { IUpdateFeeRequestData } from "../models/fees.models";

const setFees = async (data: IUpdateFeeRequestData) => {
  return authApi
    .post("/fees", data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const getDefaultRefererFee = async () => {
  return authApi
    .get("/fees/getDefaultRefererFee")
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const getDefaultTraderFee = async () => {
  return authApi
    .get("/fees/getDefaultTraderFee")
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useSetFees = (
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["UpdateFees"],
    mutationFn: (data: IUpdateFeeRequestData) => setFees(data),
    onSuccess,
    onError,
  });
};

export const useGetDefaultRefererFee = () => {
  const {
    data: defaultRefererFee,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["DefaultRefererFee"],
    queryFn: getDefaultRefererFee,
  });

  return {
    defaultRefererFee,
    isLoading,
    isError,
    refetch,
  };
};

export const useGetDefaultTraderFee = () => {
  const {
    data: defaultTraderFee,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["DefaultTraderFee"],
    queryFn: getDefaultTraderFee,
  });

  return {
    defaultTraderFee,
    isLoading,
    isError,
    refetch,
  };
};
