import React from "react";
import Navbar from "../Navbar";
import Header from "../Header";
import { Outlet } from "react-router-dom";

const RootWrapper: React.FC = () => {
  return (
    <div className="wrapper">
      <Navbar />
      <div className="page">
        <Header />
        <Outlet />
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default RootWrapper;
