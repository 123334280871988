import React, { useEffect, useState } from "react";
import { useGetChats } from "../../services/chat.services";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";
import ChatModal from "./ChatModal";

const Chats: React.FC = () => {
  const [filter, setFilter] = useState<"requests" | "unfinished">("requests");
  const [isChatModalOpen, setIsChatModalOpen] = useState<boolean>(false);
  const [ticketId, setTicketId] = useState<number>();

  const queryParams = {
    limit: 10,
    isFinished: filter === "unfinished",
    isChat: filter === "requests",
  };

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetChats(queryParams);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
          document.documentElement.scrollHeight - 100 &&
        hasNextPage &&
        !isFetchingNextPage
      ) {
        fetchNextPage();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  if (isLoading) return <Loading />;

  return (
    <main className="main chats">
      {isFetchingNextPage && <Loading />}
      <h1 className="chats__title title">Чаты</h1>

      <div className="chats__filter filter">
        <div className="filter__item">
          <input
            id="requests"
            type="radio"
            name="chats"
            className="filter__input"
            checked={filter === "requests"}
            onChange={() => setFilter("requests")}
          />
          <label htmlFor="requests" className="filter__label">
            Обращения
          </label>
        </div>
        <div className="filter__item">
          <input
            id="unfinished"
            type="radio"
            name="chats"
            className="filter__input"
            checked={filter === "unfinished"}
            onChange={() => setFilter("unfinished")}
          />
          <label htmlFor="unfinished" className="filter__label">
            Незавершенные
          </label>
        </div>
      </div>

      <div className="chats__items">
        {data?.pages?.map((page) =>
          page?.map((chat: any) => {
            console.log(chat);
            return (
              <button
                key={chat.chatMessage_ticketId}
                className="chats__item item-chats"
                onClick={() => {
                  setTicketId(chat.chatMessage_ticketId);
                  setIsChatModalOpen(true);
                }}
              >
                <div className="item-chats__data item-chats__data--order">
                  #{chat.chatMessage_ticketId}
                </div>
                <div className="item-chats__data">
                  {chat?.lastmessageat.split("T")[0]}{" "}
                  {new Date(chat.lastmessageat).toLocaleTimeString()}
                </div>
                <div className="item-chats__data">
                  <div
                    className={`item-chats__message ${
                      chat?.unreadcount >= 1 ? "message-new" : ""
                    }`}
                  >
                    <div className="item-chats__message-icon"></div>
                    <span className="item-chats__message-count">
                      {chat?.unreadcount >= 1
                        ? chat?.unreadcount
                        : chat.messagecount}
                    </span>
                  </div>
                </div>
              </button>
            );
          })
        )}
      </div>

      <Modal
        title="Чат"
        isOpen={isChatModalOpen}
        onClose={() => {
          setIsChatModalOpen(false);
        }}
        isChat
      >
        <ChatModal ticketId={ticketId} />
      </Modal>
    </main>
  );
};

export default Chats;
