import { useMutation, useQuery } from "@tanstack/react-query";
import authApi from "../config/authApi.config";

const getLaguages = async () => {
  return authApi
    .get("/languages")
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const addLanguage = async (data: { code: string; name: string }) => {
  return authApi
    .post("/languages", data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const deleteLanguage = async (id: string) => {
  return authApi
    .delete(`/languages/${id}`)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useGetLanguages = () => {
  const {
    data: languages,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["Languages"],
    queryFn: getLaguages,
  });

  return {
    languages,
    isLoading,
    isError,
    refetch,
  };
};

export const useAddLanguage = (
  onSuccess?: () => void,
  onError?: () => void
) => {
  return useMutation({
    mutationKey: ["AddLanguage"],
    mutationFn: (data: { code: string; name: string }) => addLanguage(data),
    onSuccess,
    onError,
  });
};

export const useDeleteLanguage = (
  onSuccess?: () => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["DeleteLanguage"],
    mutationFn: (id: string) => deleteLanguage(id),
    onSuccess,
    onError,
  });
};
