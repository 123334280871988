import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { IAddCurrencyPairRequestData } from "../../models/currency-pairs.models";
import { useGetCurrencyList } from "../../services/currencies.services";
import { useGetCountries } from "../../services/addresses.services";

interface IProps {
  returnFormInstance: UseFormReturn<{ countryId: number; city: string }>;
  onSubmit: (data: { countryId: number; city: string }) => void;
}

const NewCityModal: React.FC<IProps> = ({ returnFormInstance, onSubmit }) => {
  const { countries } = useGetCountries();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = returnFormInstance;

  return (
    <div className="create-admin">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="create-admin__form form"
      >
        {/* Первая валюта */}
        <Controller
          name="countryId"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="countryId" className="form__label">
                Первая Валюта
              </label>
              <select
                id="countryId"
                className="form-control"
                value={value}
                onChange={onChange}
              >
                <option value="">Выберите страну</option>
                {countries?.map((country: any) => (
                  <option key={country.id} value={country.id}>
                    {country.country}
                  </option>
                ))}
              </select>
              {errors.countryId?.message && (
                <p className="error">{errors.countryId?.message}</p>
              )}
            </div>
          )}
        />

        <Controller
          name="city"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="city" className="form__label">
                Название города
              </label>
              <input
                id="city"
                className="form-control"
                type="text"
                placeholder="Введите курс обмена"
                value={value}
                onChange={onChange}
              />
              {errors.city?.message && (
                <p className="error">{errors.city?.message}</p>
              )}
            </div>
          )}
        />

        {/* Кнопка добавления */}
        <button
          type="submit"
          className="create-admin__button button"
          data-animation="fadeInUp"
        >
          Добавить
        </button>
      </form>
    </div>
  );
};

export default NewCityModal;
