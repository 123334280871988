import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import authApi from "../config/authApi.config";
import { IUpdateUser, IUser, IUserResponse } from "../models/user.models";

const buildQueryParams = (params: any) => {
  return Object.keys(params)
    .filter((key) => params[key] !== undefined && params[key] !== null)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");
};

const createReferrer = async (data: { tgUserName: string }) => {
  return authApi
    .post("/auth/admin/createReferer", data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const getUsers = async ({
  pageParam = 1,
  sort,
  limit,
  keyword,
  filters,
}: any) => {
  const queryParams = buildQueryParams({
    sort,
    limit,
    keyword,
    page: pageParam,
    filters,
  });

  const url = `/users?${queryParams}`;

  return authApi
    .get(url)
    .then((res) => res.data as IUserResponse)
    .catch((error) => {
      throw new Error(error);
    });
};

const createAdmin = async (data: { email: string; password: string }) => {
  return authApi
    .post("/auth/admin/register", data)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const deleteUser = async (id: string) => {
  return authApi
    .delete(`/users/${id}`)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const updateUser = async (user: Partial<IUpdateUser>, id: string) => {
  return authApi
    .patch(`/users/${id}`, user)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const getMe = async () => {
  return authApi
    .get("/auth/me")
    .then((res) => res.data as IUser)
    .catch((error) => {
      throw new Error(error);
    });
};

const getUser = async (id: number) => {
  return authApi
    .get(`/users/${id}`)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useGetUser = (id: number) => {
  const {
    data: user,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["User", id],
    queryFn: () => getUser(id),
    enabled: !!id,
  });

  return {
    user,
    isLoading,
    isError,
    refetch,
  };
};

export const useCreateReferrer = (
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["CreateReferrer"],
    mutationFn: (data: { tgUserName: string }) => createReferrer(data),
    onSuccess,
    onError,
  });
};

export const useGetUsers = (
  sort?: string,
  limit?: string,
  keyword?: string,
  filters?: any
) => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
    refetch,
  } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ["Users", sort, limit, keyword, filters],
    queryFn: ({ pageParam }) =>
      getUsers({ pageParam, sort, limit, keyword, filters }),
    getNextPageParam: (lastPage) => (lastPage.hasNextPage ? 1 : undefined),
  });

  const users = data?.pages.flatMap((page) => page.data) || [];

  return {
    users,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
    refetch,
  };
};

export const useCreateAdmin = (
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["NewAdmin"],
    mutationFn: (data: { email: string; password: string }) =>
      createAdmin(data),
    onSuccess,
    onError,
  });
};

export const useUpdateUser = (
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["UpdateUser"],
    mutationFn: (data: { user: Partial<IUpdateUser>; id: string }) =>
      updateUser(data.user, data.id),
    onSuccess,
    onError,
  });
};

export const useDeleteUser = (
  onSuccess?: () => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["DeleteUser"],
    mutationFn: (id: string) => deleteUser(id),
    onSuccess,
    onError,
  });
};

export const useGetMe = () => {
  const {
    data: me,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["Me"],
    queryFn: getMe,
  });

  return {
    me,
    isLoading,
    isError,
  };
};
