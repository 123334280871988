import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useGetCurrencyList } from "../../services/currencies.services";

interface IProps {
  returnFormInstance: UseFormReturn<{ exchangeRate: number }>;
  onSubmit: (data: { exchangeRate: number }) => void;
}

const UpdateCurrencyPairModal: React.FC<IProps> = ({
  returnFormInstance,
  onSubmit,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = returnFormInstance;

  return (
    <div className="create-admin">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="create-admin__form form"
      >
        <Controller
          name="exchangeRate"
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="exchangeRate" className="form__label">
                Обменный курс
              </label>
              <input
                id="exchangeRate"
                className="form-control"
                type="number"
                step="0.01" // Позволяет вводить числа с плавающей запятой
                placeholder="Введите курс обмена"
                value={value}
                onChange={onChange}
              />
              {errors.exchangeRate?.message && (
                <p className="error">{errors.exchangeRate?.message}</p>
              )}
            </div>
          )}
        />

        {/* Кнопка добавления */}
        <button
          type="submit"
          className="create-admin__button button"
          data-animation="fadeInUp"
        >
          Обновить
        </button>
      </form>
    </div>
  );
};

export default UpdateCurrencyPairModal;
