import React from "react";
import { Controller, useForm } from "react-hook-form";
import { ICurrency } from "../../models/currency.models";

interface IProps {
  currency: ICurrency; // Получаем валюту для редактирования
  onSubmit: (data: ICurrency) => void;
}

const EditCurrencyModal: React.FC<IProps> = ({ currency, onSubmit }) => {
  const { control, handleSubmit, getValues, formState: { isDirty } } = useForm<ICurrency>({
    defaultValues: {
      id: currency.id,
      code: currency.code,
      name: currency.name,
      symbol: currency.symbol,
      limit: currency.limit,
    },
  });

  const handleSubmitForm = () => {
    const data = getValues();
    onSubmit(data)
  }

  return (
    <div className="create-admin">
      <form onSubmit={handleSubmit(onSubmit)} className="create-admin__form form">
        <Controller
          name="code"
          control={control}
          render={({ field }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="code" className="form__label">
                Код валюты
              </label>
              <input
                id="code"
                className="form-control"
                type="text"
                placeholder="USD"
                {...field}
              />
            </div>
          )}
        />

        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="name" className="form__label">
                Название
              </label>
              <input
                id="name"
                className="form-control"
                type="text"
                placeholder="United States Dollar"
                {...field}
              />
            </div>
          )}
        />

        <Controller
          name="symbol"
          control={control}
          render={({ field }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="symbol" className="form__label">
                Символ
              </label>
              <input
                id="symbol"
                className="form-control"
                type="text"
                placeholder="$"
                {...field}
              />
            </div>
          )}
        />

        <Controller
          name="limit"
          control={control}
          render={({ field }) => (
            <div className="create-admin__item form__item">
              <label htmlFor="limit" className="form__label">
                Лимит
              </label>
              <input
                id="limit"
                className="form-control"
                type="number"
                placeholder="1000"
                {...field}
              />
            </div>
          )}
        />

        <button type="button" disabled={!isDirty} onClick={handleSubmitForm} className="create-admin__button button">
          Сохранить изменения
        </button>
      </form>
    </div>
  );
};

export default EditCurrencyModal;
