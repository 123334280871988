import React, { useEffect, useRef, useState } from "react";

// Логика для блокировки скролла
const disableScroll = () => {
  const scrollPosition = window.scrollY;
  document.body.style.top = `-${scrollPosition}px`;
  document.body.style.position = "fixed";
  document.body.style.width = "100%";
  document.body.style.overflow = "hidden";
};

const enableScroll = () => {
  const scrollPosition = parseInt(document.body.style.top || "0", 10) * -1;
  document.body.style.position = "";
  document.body.style.top = "";
  document.body.style.overflow = "";
  window.scrollTo(0, scrollPosition);
};

// Компонент модального окна
const Modal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  animation?: string;
  speed?: number;
  children: React.ReactNode;
  title: string;
  isChat?: boolean;
}> = ({
  isOpen,
  onClose,
  animation = "fade",
  speed = 500,
  children,
  title,
  isChat = false,
}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      disableScroll();
      setIsAnimating(true);
    } else {
      enableScroll();
      setIsAnimating(false);
    }

    return () => {
      enableScroll();
    };
  }, [isOpen, speed]);

  const handleClose = () => {
    setIsAnimating(true);
    setTimeout(() => {
      onClose();
    }, speed);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape" && isOpen) {
      handleClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, isAnimating]);

  if (!isOpen) return null;

  return (
    <div
      className={`modal ${isOpen ? "is-open" : ""}`}
      style={{ transitionDuration: `${speed}ms` }}
      ref={modalRef}
      onClick={(e) => {
        if (e.target === modalRef.current) handleClose();
      }}
    >
      <div
        className={`modal__container ${
          isChat ? "modal-chat__container" : ""
        } ${animation} ${isAnimating ? "animate-open" : ""} ${
          isOpen ? "modal-open" : ""
        }`}
        role="dialog"
        aria-modal="true"
      >
        <button
          className="modal__close js-modal-close"
          onClick={handleClose}
          aria-label="Close modal"
        >
          <svg className="modal__close-icon">
            <use xlinkHref="img/sprite.svg#close"></use>
          </svg>
        </button>
        <h2 className="modal__title">{title}</h2>
        {children}
      </div>
    </div>
  );
};

export default Modal;
