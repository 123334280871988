import React from "react";
import moment from "moment";
import clsx from "clsx";

interface UserTableProps {
  users: any[];
  handleDeleteUser: (id: string) => void;
  handleBlockUser: (id: string) => void;
  handleUnblockUser: (id: string) => void;
  handleChangeUser: (id: number) => void;
}

const UserTable: React.FC<UserTableProps> = ({
  users,
  handleDeleteUser,
  handleBlockUser,
  handleUnblockUser,
  handleChangeUser,
}) => {
  return (
    <div className="users__table table-users table">
      <div className="table-users__items table__items table__items--head">
        <div className="table__item">User ID</div>
        <div className="table__item">Name</div>
        <div className="table__item">Status</div>
        <div className="table__item">Type</div>
        <div className="table__item">Reg date\Time</div>
        <div className="table__item">Orders</div>
        <div className="table__item">Guarantee amount (USDT)</div>
        <div className="table__item">Рефералы</div>
        <div className="table__item">Комиссии/начисление</div>
      </div>
      {users?.map((user) => (
        <div className="table-users__items table__items" key={user?.id}>
          <div className="table__item table__item--id">{user?.id}</div>
          <div className="table__item dropdown">
            <button className="dropdown__title">
              {user?.userName}
              <svg className="dropdown__arrow-icon">
                <use xlinkHref="/img/sprite.svg#arrow"></use>
              </svg>
            </button>
            <ul className="dropdown__list">
              <li className="dropdown__item">
                <button
                  className={clsx("dropdown__action", {
                    "dropdown__action--unlock": user.status.name !== "Active",
                  })}
                  disabled={user.status.name !== "Active"}
                  onClick={() => handleBlockUser(user.id.toString())}
                >
                  Заблокировать
                </button>
              </li>
              <li className="dropdown__item">
                <button
                  className={clsx("dropdown__action", {
                    "dropdown__action--unlock": user.status.name !== "Inactive",
                  })}
                  disabled={user.status.name !== "Inactive"}
                  onClick={() => handleUnblockUser(user.id.toString())}
                >
                  Разблокировать
                </button>
              </li>
              <li className="dropdown__item">
                <button
                  onClick={() => handleDeleteUser(user?.id.toString())}
                  className="dropdown__action dropdown__action--del"
                >
                  Удалить
                </button>
              </li>
            </ul>
          </div>
          <div className="table__item">{user?.status?.name}</div>
          <div className="table__item">{user?.role?.name}</div>
          <div className="table__item">
            {moment(user?.createdAt).format("DD.MM.YYYY hh:mm")}
          </div>
          <div className="table__item">{user?.orderCount}</div>
          <div className="table__item">{user?.currentBalance || "-"}</div>
          <div className="table__item">{user?.referralAmount || "-"}</div>
          <div className="table__item">
            <div
              className={clsx("table__item-commissions", {
                "table__item-commissions--red":
                  user.role.id === 4 || user.role.id === 5,
                "table__item-commissions--green": user.role.id === 2,
              })}
              onClick={
                user.role.id !== 3 || user.role.id !== 1
                  ? () => handleChangeUser(user.id)
                  : undefined
              }
            >
              {user.role.id === 3 || user.role.id === 1 ? "-" : user.fee || "-"}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserTable;
