import React, { useState } from "react";
import * as yup from "yup";
import Modal from "../../components/Modal";
import Loading from "../../components/Loading";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import NewLanguageModal from "./NewLanguageModal";
import {
  useAddLanguage,
  useDeleteLanguage,
  useGetLanguages,
} from "../../services/language.services";

const Languages: React.FC = () => {
  const [isNewLanguageModalOpen, setIsNewLanguageModalOpen] =
    useState<boolean>(false);

  const NewLanguageFormSchema = yup.object().shape({
    code: yup.string().required("Код языка является обязательным полем!"),
    name: yup.string().required("Название языка является обязательным полем!"),
    file_path: yup
      .string()
      .required("Языковой пакет является обязательным полем!"),
  });

  const useNewLanguageReturnForm = useForm({
    resolver: yupResolver(NewLanguageFormSchema),
  });

  const { reset } = useNewLanguageReturnForm;

  const { languages, refetch, isLoading } = useGetLanguages();

  const { mutate: addLanguage } = useAddLanguage(
    () => {
      setIsNewLanguageModalOpen(false);
      toast.success("Язык успешно добавлен!");
      reset();
      refetch();
    },
    () => {
      toast.error("Что-то пошло не так!");
    }
  );

  const { mutate: deleteLanguage } = useDeleteLanguage(
    () => {
      refetch();
      toast.success("Язык успешно удален!");
    },
    () => {
      toast.error("Что-то пошло не так!");
    }
  );

  const handleSubmitNewLanguageForm = (data: {
    code: string;
    name: string;
  }) => {
    addLanguage(data);
  };

  const handleDeleteLanguage = (id: string) => {
    deleteLanguage(id);
  };

  return (
    <main className="main exchange">
      {isLoading && <Loading />}
      <div className="exchange__header">
        <h1 className="exchange__title title">Доступные языки</h1>
        <button
          onClick={() => setIsNewLanguageModalOpen(true)}
          className="exchange__button button button--top"
        >
          Добавить новый язык
        </button>
      </div>

      <div className="exchange__table table-exchange table">
        <div className="table-exchange__items table__items table__items--head">
          <div className="table__item">ID</div>
          <div className="table__item">Код</div>
          <div className="table__item">Название</div>
          <div className="table__item"></div>
          <div className="table__item"></div>
        </div>
        {languages?.map(
          (language: { id: string; name: string; code: string }) => (
            <div className="table-exchange__items table__items">
              <div className="table__item">{language?.id}</div>
              <div className="table__item">{language?.code}</div>
              <div className="table__item">{language?.name}</div>
              <div className="table__item"></div>
              <div className="table__item">
                <div className="table__item-action">
                  <button
                    onClick={() => handleDeleteLanguage(language?.id)}
                    className="table__item-img"
                  >
                    <img
                      className="table__item-icon"
                      src="/img/svg/del.svg"
                      alt="icon"
                    />
                  </button>
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <Modal
        title="Новый язык"
        isOpen={isNewLanguageModalOpen}
        onClose={() => setIsNewLanguageModalOpen(false)}
      >
        <NewLanguageModal
          returnFormInstance={useNewLanguageReturnForm}
          onSubmit={handleSubmitNewLanguageForm}
        />
      </Modal>
    </main>
  );
};

export default Languages;
