import React, { useEffect, useState } from "react";
import { useGetDisputesChats } from "../../services/chat.services";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";
import ChatModal from "./ChatModal";

const Disputes: React.FC = () => {
  const [filter, setFilter] = useState<"requests" | "unfinished">("requests");
  const [isChatModalOpen, setIsChatModalOpen] = useState<boolean>(false);
  const [ticketId, setTicketId] = useState<number>();

  const queryParams = {
    limit: 10,
    isFinished: filter === "unfinished",
    isChat: true,
  };

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError: isChatsError,
    refetch,
  } = useGetDisputesChats(queryParams);

  // Обработчик бесконечной прокрутки
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
          document.documentElement.scrollHeight - 100 &&
        hasNextPage &&
        !isFetchingNextPage
      ) {
        fetchNextPage();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  // Обработчик открытия модального окна чата
  const handleOpenChatModal = (ticketId: number) => {
    setTicketId(ticketId);
    setIsChatModalOpen(true);
  };

  // Обработчик закрытия модального окна чата
  const handleCloseChatModal = () => {
    refetch();
    setIsChatModalOpen(false);
  };

  if (isLoading) return <Loading />;
  if (isChatsError) return <p className="error">Ошибка при загрузке чатов!</p>;

  return (
    <main
      className="main chats"
      style={{
        position: "relative",
        overflowY: "auto",
        height: "100%",
      }}
    >
      {isFetchingNextPage && <Loading />}
      <h1 className="chats__title title">Чаты</h1>

      {/* Фильтр чатов */}
      <div className="chats__filter filter">
        <div className="filter__item">
          <input
            id="requests"
            type="radio"
            name="chats"
            className="filter__input"
            checked={filter === "requests"}
            onChange={() => setFilter("requests")}
          />
          <label htmlFor="requests" className="filter__label">
            Обращения
          </label>
        </div>
        <div className="filter__item">
          <input
            id="unfinished"
            type="radio"
            name="chats"
            className="filter__input"
            checked={filter === "unfinished"}
            onChange={() => setFilter("unfinished")}
          />
          <label htmlFor="unfinished" className="filter__label">
            Незавершенные
          </label>
        </div>
      </div>

      {/* Список чатов */}
      <div className="chats__items">
        {data?.pages?.map((page) =>
          page?.map((chat: any) => (
            <button
              key={chat.chatMessage_orderId}
              className="chats__item item-chats"
              onClick={() => handleOpenChatModal(chat.chatMessage_orderId)}
            >
              <div className="item-chats__data item-chats__data--order">
                #{chat.chatMessage_orderId}
              </div>
              <div className="item-chats__data">
                {new Date(chat.lastmessageat).toLocaleDateString()}{" "}
                {new Date(chat.lastmessageat).toLocaleTimeString()}
              </div>
              <div className="item-chats__data">
                <div
                  className={`item-chats__message ${
                    chat?.unreadcount >= 1 ? "message-new" : ""
                  }`}
                >
                  <div className="item-chats__message-icon"></div>
                  <span className="item-chats__message-count">
                    {chat?.unreadcount >= 1
                      ? chat?.unreadcount
                      : chat.messagecount}
                  </span>
                </div>
              </div>
            </button>
          ))
        )}
      </div>

      {/* Модальное окно чата */}
      <Modal
        title="Чат"
        isOpen={isChatModalOpen}
        onClose={handleCloseChatModal}
        isChat
      >
        <ChatModal ticketId={ticketId} onClose={handleCloseChatModal} />
      </Modal>
    </main>
  );
};

export default Disputes;
