import axios from "axios";
import authApi from "../config/authApi.config";
import { useMutation, useQuery } from "@tanstack/react-query";

const getAddresses = async () => {
  return axios
    .get("https://bobster.freeblock.site/api/v1/addresses")
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

const addCountry = async (country: string) => {
  return authApi
    .post(`/addresses/country?country=${country}`)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useAddCountry = (
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationFn: (country: string) => addCountry(country),
    onSuccess,
    onError,
  });
};

const getCountries = async () => {
  return authApi
    .get("/addresses/countries")
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useGetCountries = () => {
  const {
    data: countries,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["Countries"],
    queryFn: getCountries,
  });

  return {
    countries,
    isLoading,
    isError,
    refetch,
  };
};

const updateCountry = async (data: { id: string; newCity: string }) => {
  return authApi
    .patch(`/addresses/country?id=${data.id}&newCity=${data.newCity}`)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useUpdateCountry = (
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationFn: (data: { id: string; newCity: string }) => updateCountry(data),
    onSuccess,
    onError,
  });
};

const deleteCountry = async (id: string) => {
  return authApi
    .delete(`/addresses/country?id=${id}`)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useDeleteCountry = (
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationFn: deleteCountry,
    onSuccess,
    onError,
  });
};

export const useGetAddresses = () => {
  const {
    data: addresses,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["Addresses"],
    queryFn: getAddresses,
  });

  return {
    addresses,
    isLoading,
    isError,
  };
};

const addCity = async (data: { countryId: number; city: string }) => {
  return authApi
    .post(`/addresses/city?countryId=${data.countryId}&city=${data.city}`)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useAddCity = (
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["AddCity"],
    mutationFn: (data: { countryId: number; city: string }) => addCity(data),
    onSuccess,
    onError,
  });
};

const getCities = async (countryId?: number) => {
  return authApi
    .get(`/addresses/cities`, { params: { countryId } })
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useGetCities = (countryId?: number) => {
  const {
    data: cities,
    isLoading,
    isError,
    refetch,
    
  } = useQuery({
    queryKey: ["Cities", countryId], // Теперь указываем countryId в ключе
    queryFn: () => getCities(countryId),
    enabled: !!countryId, // Запрос выполняется только если есть countryId
  });

  return {
    cities,
    isLoading,
    isError,
    refetch,
  };
};

const deleteCity = async (id: number) => {
  return authApi
    .delete(`/addresses/city?id=${id}`)
    .then((res) => res.data)
    .catch((error) => {
      throw new Error(error);
    });
};

export const useDeleteCity = (
  onSuccess?: (data: any) => void,
  onError?: (error: any) => void
) => {
  return useMutation({
    mutationKey: ["DeleteCity"],
    mutationFn: (id: number) => deleteCity(id),
    onSuccess,
    onError,
  });
};
