import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  useCreateAdmin,
  useCreateReferrer,
  useDeleteUser,
  useGetUser,
  useGetUsers,
  useUpdateUser,
} from "../../services/user.services";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom"; // Добавляем хуки для работы с URL

import NewAdminModal from "./NewAdminModal";
import NewReferrerModal from "./NewReferrerModal";
import Modal from "../../components/Modal";
import UserFilter from "./UserFilter";
import UserTable from "./UserTable";
import Loading from "../../components/Loading";
import { useSetFees } from "../../services/fees.services";
import UpdateUserFeeModal from "./UpdateUserFeeModal";

const Users: React.FC = () => {
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const [isNewAdminModalOpen, setIsNewAdminModalOpen] = useState(false);
  const [isNewReferrerModalOpen, setIsNewReferrerModalOpen] = useState(false);
  const [isUpdateUserModalOpen, setIsUpdateUserModalOpen] =
    useState<boolean>(false);
  const { user, refetch: refetchUser } = useGetUser(selectedUserId!);

  const navigate = useNavigate(); // Хук для изменения URL
  const location = useLocation(); // Хук для получения текущего URL

  // Извлечение фильтров из URL-параметров
  const getFilterFromUrl = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const roleId = searchParams.get("roleId")
      ? Number(searchParams.get("roleId"))
      : 3; // Значение по умолчанию
    return { roles: [{ id: roleId }] };
  }, [location.search]);

  const [filter, setFilter] = useState(getFilterFromUrl);

  const {
    users,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useGetUsers(undefined, undefined, undefined, JSON.stringify(filter));

  const { mutate: createAdmin } = useCreateAdmin(
    () => {
      refetch();
      toast.success("Администратор успешно добавлен!");
      resetNewAdminForm();
      setIsNewAdminModalOpen(false);
    },
    () => toast.error("Что-то пошло не так...")
  );

  const { mutate: deleteUser } = useDeleteUser(
    () => {
      refetch();
      toast.success("Пользователь успешно удален!");
    },
    () => toast.error("Что-то пошло не так...")
  );

  const { mutate: updateUser } = useUpdateUser(
    () => {
      refetch();
      toast.success("Пользователь успешно обновлен!");
    },
    () => toast.error("Что-то пошло не так...")
  );

  const { mutate: createReferrer } = useCreateReferrer(
    () => {
      refetch();
      toast.success("Реферал успено создан!");
      setIsNewReferrerModalOpen(false);
      resetCreateReferer();
    },
    () => toast.error("Что-то пошло не так...")
  );

  const { mutate: setFee } = useSetFees(() => {
    setIsUpdateUserModalOpen(false);
    toast.success("Комиссия успешно изменена!");
    refetch();
  });

  const loadMoreRef = useRef<HTMLDivElement | null>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const NewAdminFormSchema = yup.object().shape({
    email: yup
      .string()
      .email("Неверный формат электронной почты!")
      .required("Почта обязательное поле!"),
    password: yup
      .string()
      .required("Пароль является обязательным полем!")
      .min(6, "Пароль должен содержать минимум 6 символов"),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref("password"), ""], "Пароли должны совпадать!")
      .required("Повторите пароль"),
  });

  const UpdateComissionFormSchema = yup.object().shape({
    fee: yup
      .number()
      .typeError("Введите корректное значение комиссии")
      .min(0, "Комиссия не может быть меньше 0")
      .required("Комиссия обязательна"),
    fromDate: yup.string().when("isDeferred", {
      is: (isDeferred: boolean) => isDeferred === true,
      then: (schema) =>
        schema.required("Дата обязательна для отложенного запуска"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const NewReferrerFormSchema = yup.object().shape({
    tgUserName: yup
      .string()
      .required("Telegram ID пользователя является обязательным полем!"),
  });

  const newAdminReturnForm = useForm({
    resolver: yupResolver(NewAdminFormSchema),
  });

  const updateComissionReturnForm = useForm({
    resolver: yupResolver(UpdateComissionFormSchema),
  });

  const { reset: resetNewAdminForm } = newAdminReturnForm;

  const newReferrerReturnForm = useForm({
    resolver: yupResolver(NewReferrerFormSchema),
  });

  const { reset: resetCreateReferer } = newReferrerReturnForm;

  const handleSubmitNewAdminForm = (data: {
    email: string;
    password: string;
    repeatPassword: string;
  }) => {
    createAdmin({ email: data.email, password: data.password });
    setIsNewAdminModalOpen(false);
  };

  const handleSubmitNewReferrerForm = (data: { tgUserName: string }) => {
    createReferrer(data);
    setIsNewReferrerModalOpen(false);
  };

  const handleDeleteUser = (id: string) => deleteUser(id);
  const handleBlockUser = (id: string) =>
    updateUser({ user: { status: { id: 2 } }, id });
  const handleUnblockUser = (id: string) =>
    updateUser({ user: { status: { id: 1 } }, id });

  const handleChangeUser = (id: number) => {
    setSelectedUserId(id);
    setIsUpdateUserModalOpen(true);
  };

  const handleUpdateUserComissionForm = (data: {
    fee: number;
    fromDate?: string;
  }) => {
    setFee(
      data.fromDate
        ? {
            userId: user.id,
            userType: user.role.id,
            fee: data.fee,
            fromDate: data.fromDate,
          }
        : {
            userId: user.id,
            userType: user.role.id,
            fee: data.fee,
          }
    );
  };

  const observePagination = useCallback(
    (node: any) => {
      if (isLoading || !hasNextPage || isFetchingNextPage) return;
      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [isLoading, hasNextPage, isFetchingNextPage, fetchNextPage]
  );

  useEffect(() => {
    if (loadMoreRef.current) observePagination(loadMoreRef.current);
  }, [observePagination]);

  // Обновляем фильтр при изменении URL
  useEffect(() => {
    const currentFilter = getFilterFromUrl();
    setFilter(currentFilter);
    refetch(); // Перезагружаем данные после изменения фильтра
  }, [location.search, getFilterFromUrl, refetch]); // Перехватываем изменения в URL

  const handleFilterChange = (newFilter: { roles: { id: number }[] }) => {
    // Преобразуем фильтр в строку URL
    const searchParams = new URLSearchParams();
    searchParams.set("roleId", newFilter.roles[0].id.toString());
    navigate(`?${searchParams.toString()}`); // Изменяем URL
  };

  useEffect(() => {
    if (selectedUserId) {
      refetchUser();
    }
  }, [selectedUserId]);

  return (
    <main className="main users">
      {isLoading && <Loading />}
      <h1 className="users__title title">Пользователи</h1>
      <UserFilter
        filter={filter}
        setFilter={handleFilterChange}
        toggleNewAdminModal={() => setIsNewAdminModalOpen(true)}
        toggleNewReferrerModal={() => setIsNewReferrerModalOpen(true)}
      />
      <UserTable
        users={users}
        handleDeleteUser={handleDeleteUser}
        handleBlockUser={handleBlockUser}
        handleUnblockUser={handleUnblockUser}
        handleChangeUser={handleChangeUser}
      />
      <div ref={loadMoreRef}></div>

      <Modal
        isOpen={isNewAdminModalOpen}
        onClose={() => setIsNewAdminModalOpen(false)}
        title="Создать нового админа"
      >
        <NewAdminModal
          returnFormInstance={newAdminReturnForm}
          onSubmit={handleSubmitNewAdminForm}
        />
      </Modal>

      <Modal
        isOpen={isNewReferrerModalOpen}
        onClose={() => setIsNewReferrerModalOpen(false)}
        title="Создать нового реферрера"
      >
        <NewReferrerModal
          returnFormInstance={newReferrerReturnForm}
          onSubmit={handleSubmitNewReferrerForm}
        />
      </Modal>

      <Modal
        isOpen={isUpdateUserModalOpen}
        onClose={() => setIsUpdateUserModalOpen(false)}
        title={
          user?.role?.id === 2
            ? `Изменить начисление реферера ${user?.userName || ""} ID ${
                user?.id
              }`
            : `Изменить начисление трейдера ${user?.userName || ""} ID ${
                user?.id
              }`
        }
      >
        <UpdateUserFeeModal
          returnFormInstance={updateComissionReturnForm}
          onSubmit={handleUpdateUserComissionForm}
          user={user}
        />
      </Modal>
    </main>
  );
};

export default Users;
